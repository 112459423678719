import type { ActionDefinition } from './workflow';

function isValidIdentifier(property: string) {
  const regex = /^[a-zA-Z_$][0-9a-zA-Z_$]*$/;
  return regex.test(property);
}
export function createPathProxy(path = '###$$$###'): any {
  return new Proxy(
    () => {
      /* noop */
    },
    {
      get(target, property) {
        if (property === 'valueOf') {
          return () => path;
        }
        if (property === Symbol.toPrimitive) {
          return (hint: any) => {
            if (hint === 'string') {
              return path;
            }
            throw new Error('Invalid hint');
          };
        }

        if (path === '###$$$###') {
          return createPathProxy(`@trigger:` + String(property));
        }

        return createPathProxy(
          path +
            (typeof property === 'string'
              ? isValidIdentifier(property)
                ? `.${property}`
                : `['${property}']`
              : `.${String(property)}`),
        );
      },
      apply(target, thisArg, argumentsList) {
        const argsString = argumentsList
          .map((arg) => JSON.stringify(arg))
          .join(', ');
        return createPathProxy(path + `(${argsString})`);
      },
    },
  );
}

export function createEnvProxy() {
  return new Proxy(process.env, {
    get(target, property, receiver) {
      if (typeof property === 'string') {
        return `process.env.${property}`;
      }
      return Reflect.get(target, property, receiver);
    },
  });
}

export function getErrors(fn: () => void) {
  try {
    fn();
    return [];
  } catch (e) {
    return [e].flat();
  }
}

export function coerceString(
  value?: string | boolean | number | null | string[],
) {
  if (value === undefined || value === null) {
    return value;
  }
  if (typeof value === 'number') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.join('.');
  }
  return value.valueOf();
}
export const actionSymbol: unique symbol = Symbol('description');

export function isActionDefinition(obj: unknown): obj is ActionDefinition {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    (obj as any)[actionSymbol] === true
  );
}
