import type { diagnostic } from '@january/sdk/analyzer';
import { Checker } from '@january/sdk/analyzer';
import type {
  EmitterWebhookEvent,
  EmitterWebhookEventName,
} from '@octokit/webhooks';
import type {
  WebhookEventMap,
  WebhookEventName,
} from '@octokit/webhooks-types';
import type * as morph from 'ts-morph';
import { Project, SyntaxKind } from 'ts-morph';

import type { Command } from '@faslh/isomorphic';
import { normalizeWorkflowPath } from '@faslh/utils';

import type { RuleFn } from '../evaluate';
import { createSchema, validateInput } from '../validation';
import type { Policy } from './feature';
import { output } from './output';
import type { actionSymbol } from './utils';
import {
  createEnvProxy,
  createPathProxy,
  getErrors,
  isActionDefinition,
} from './utils';

if (typeof process === 'undefined') {
  Object.defineProperty(self, 'process', {
    value: {
      env: {},
    },
  });
}

declare module '@octokit/webhooks' {
  export interface BaseWebhookEvent<TName extends WebhookEventName> {
    id: string;
    name: TName;
    payload: WebhookEventMap[TName];
  }
}

export interface TagDefinition {
  name: string;
}

export type InferTriggerArg<T> =
  T extends TriggerDefinition<infer RT, infer Mapper, infer Config>
    ? RT & { mapper: Mapper }
    : never;

export interface WorkflowDefinition<
  Trigger extends TriggerDefinition<unknown, unknown>,
  T = any,
> {
  name: string;
  trigger: Trigger;
  actions: Record<string, ActionDefinition>;
  tag: string;
  sequence: SequenceDefinition<Command<{ name: string }>>[];
  output: string;
}

export interface TriggerDefinition<
  RT,
  Mapper,
  Config = Record<string, unknown>,
> {
  type: string;
  config: Config;
  // policies?: Record<string, unknown>;
  policies?: string[];
  inputs?: Record<string, { value: string }>;
}
export interface SequenceDefinition<K> {
  source: K;
  target: K;
  data?: {
    handle: Command<{ name: string }>;
    child: boolean;
  };
}
export interface ActionDefinition {
  type: string;
  config: Record<string, unknown>;
  outputName: string;
  [actionSymbol]: boolean;
  [Symbol.hasInstance]: (instance: any) => boolean;
}
export function experimental_wokflow(features: any[]) {
  //
}
// one major flaw is that it is hard to enfore all the required fields unless we created
// languge server that would validate the code
// in any case it's better to wait for users to say whether they like it or not
// experimental_workflow(
//   tag('tasks'),
//   output('return {tasks: steps.tasks}'),
//   trigger.http({ method: 'get', path: '/' }),
//   trigger.http(method('get'), path('/'), policy('public')),
//   sequenceFor('tasks', 'search'),
//   action.search(
//     output('tasks'),
//     config(
//       useTable('tasks'),
//       limit(50),
//       pagination('deferred_joins'),
//       queryTerm('query'),
//     ),
//   ),
// ),

export function workflow<
  Trigger extends TriggerDefinition<unknown, unknown>,
  Actions extends Record<
    string,
    | ActionDefinition
    | ((trigger: InferTriggerArg<Trigger>) => ActionDefinition)
    | ((trigger: InferTriggerArg<Trigger>) => unknown)
  >,
>(
  name: string,
  config: {
    tag: string;
    output?: string;
    trigger: Trigger;
    actions: Actions;
    sequence?: SequenceDefinition<string>[];
  },
): WorkflowDefinition<Trigger> {
  const sequenceDef: SequenceDefinition<Command<{ name: string }>>[] = [];
  const actionsNames = Object.keys(config.actions);
  const sequence: SequenceDefinition<string>[] = [];

  if (config.sequence) {
    sequence.push(...config.sequence);
    // in case no sequence was defined and no actions; do nothing
    const first = sequence[0];
    if (first.source !== name) {
      sequence.unshift({
        source: name,
        target: first.source,
      });
    }
  } else {
    if (actionsNames.length) {
      sequence.push(...sequenceFor(name, ...actionsNames));
    }
  }

  for (const item of sequence) {
    if (item.source === name) {
      sequenceDef.push({
        source: {
          command: 'QueryWorkflow',
          payload: { name: item.source },
        },
        target: {
          command: 'QueryAction',
          payload: { name: item.target },
        },
      });
    } else {
      sequenceDef.push({
        source: { command: 'QueryAction', payload: { name: item.source } },
        target: { command: 'QueryAction', payload: { name: item.target } },
        data: item.data,
      });
    }
  }

  process.env = createEnvProxy();

  const actions: Record<string, ActionDefinition> = {};

  for (const [actionName, action] of Object.entries(config.actions)) {
    if (typeof action === 'function') {
      const result = action(createPathProxy() as never);
      if (isActionDefinition(result)) {
        actions[actionName] = result;
      } else {
        // there shall never be the case
        // in static evaluation we give all actions
        // action.trigger which handles this case.
      }
    } else {
      actions[actionName] = action;
    }
  }

  return {
    name,
    trigger: config.trigger,
    actions: actions,
    tag: config.tag,
    output: config.output ?? output('return {}'),
    sequence: sequenceDef,
  };
}

workflow.new = <
  Trigger extends TriggerDefinition<unknown, unknown>,
  Actions extends Record<
    string,
    | ActionDefinition
    | ((trigger: InferTriggerArg<Trigger>) => ActionDefinition)
    | ((trigger: InferTriggerArg<Trigger>) => unknown)
  >,
>(config: {
  tag: string;
  output?: string;
  trigger: Trigger;
  actions: Actions;
  sequence?: SequenceDefinition<string>[];
}): ((name: string) => WorkflowDefinition<Trigger>) => {
  return (name) => workflow(name, config);
};

workflow.rule = (({ node }, service) => {
  const reports: diagnostic.Diagnostic[] = [];
  if (!Checker.isCallExpression(node)) {
    return reports;
  }
  const [name, config] = node.arguments;

  if (typeof name === 'string') {
    if (!name.trim().length) {
      reports.push({
        message: 'Workflow name must not be empty',
        span: node.span,
        node: name as any,
        severity: 'error',
      });
    }
    if (!service.isUniqueWorkflow(name)) {
      reports.push({
        message: `Workflow name "${name}" is already defined`,
        span: node.span,
        node: node as any,
        severity: 'error',
      });
    }
  } else {
    reports.push({
      message: 'Workflow name must be a string',
      span: node.span,
      node: name as any,
      severity: 'error',
    });
  }
  if (!Checker.isObjectExpression(config)) {
    reports.push({
      message: 'Workflow config must be an object',
      span: node.span,
      node: config as any,
      severity: 'error',
    });
  }
  return reports;
}) as RuleFn;

// workflow.crud = (table: UseTable) => {
//   return [
//     workflow(table, {
//       tag: table,
//       sequence: sequenceFor(table, 'insert'),
//       actions: {
//         insert: action.insertRecord({
//           outputName: 'record',
//           config: {
//             table,
//             columns: [],
//           },
//         }),
//       }
//     }),
//   ];

// workflow.search = (
//   name: string,
//   config: {
//     tag: string;
//     output?: string;
//     trigger: TriggerDefinition;
//     actions: Record<string, ActionDefinition>;
//     sequence: SequenceDefinition<string>[];
//     fields: UseField[];
//     queryTerm?: string;
//   },
// ) => {
//   return workflow(name, {
//     ...config,
//     trigger: trigger.http({
//       path: '/search',
//       method: 'get',
//     }),
//     sequence: sequenceFor(name, 'searchMenu'),
//     actions: {
//       search: action.database.list({
//         outputName: 'tasks',
//         table: useTable('tasks'),
//         limit: 50,
//         pagination: 'deferred_joins',
//         query: query(
//           ...config.fields.map((it) =>
//             where(
//               'name',
//               'contains',
//               `@trigger:query.${config.queryTerm ?? 'query'}`,
//             ),
//           ),
//         ),
//       }),
//     },
//   });
// };

export function sequenceFor(
  action: string,
  ...is: (string | SequenceDefinition<string>[])[]
): SequenceDefinition<string>[] {
  if (is.length === 0) {
    return [
      {
        source: action,
        target: action,
      },
    ];
  }

  const sequence: SequenceDefinition<string>[] = [];
  const all = [action, ...is];

  for (let i = all.length - 1; i > 0; i--) {
    const target = all[i];
    if (typeof target === 'string') {
      if (target.includes(':')) {
        const [pathName, actionName] = target.split(':');
        sequence.push({
          source: action,
          target: actionName,
          data: {
            handle: { command: 'QueryPath', payload: { name: pathName } },
            child: true,
          },
        });
        continue;
      }
      let j = i - 1;
      let source = all[j];
      if (!source) {
        continue;
      }
      if (typeof source !== 'string') {
        sequence.push({
          source: source[0].source,
          target: target,
        });
        continue;
      }
      while (source && typeof source === 'string' && source.includes(':')) {
        source = all[--j];
      }
      if (!source) {
        continue;
      }
      sequence.push({
        source: source as string,
        target: target,
      });
    } else {
      sequence.push(...target);

      const source = all[i - 1];
      if (!source) {
        continue;
      }
      if (typeof source !== 'string') {
        // TODO: what if source was SequenceDefinition[] ?
        continue;
      }
      sequence.push({
        source: source,
        target: target[0].source,
      });
    }
  }

  return sequence;
}

// export function trigger(
//   type: string,
//   config: Record<string, unknown>,
// ): TriggerDefinition<Record<string, unknown>> {
//   return {
//     type,
//     config: config,
//   };
// }

function defineTrigger<T extends TriggerDefinition<unknown, unknown>>(
  type: string,
  config: Record<string, unknown>,
): T {
  return {
    type,
    config: config,
  } as T;
}

export namespace trigger {
  export function fromConfig(
    type: string,
    ...args: unknown[]
  ): TriggerDefinition<unknown, unknown> {
    const parts = type.split('.');
    let impl = parts.length ? (trigger as any) : defineTrigger;
    while (parts.length) {
      impl = (impl as any)[parts.shift() as any];
    }
    if (impl) {
      return impl(...args);
    }
    throw new Error(`Unknown trigger type: ${type}`);
  }
}

export namespace trigger {
  export function schedule(config: {
    pattern: string;
    immediate?: boolean;
    // policies?: string[]; // FIXME: very very rare case to use policy here unless specific date not achievable via the pattern
    // and want to use policies to control exact timing
    // anyway, we need to enable this once access to database is figured.
  }): TriggerDefinition<never, never> {
    return {
      type: 'node-cron-trigger',
      config: config,
      policies: [],
    };
  }
  schedule.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
}
export namespace trigger {
  export interface HttpTrigger {
    body: Record<string, string>;
    query: Record<string, string>;
    headers: Record<string, string>;
    path: Record<string, string>;
  }
  export type HttpTriggerConfig = {
    path: string;
    method: string;
    policies?: string[];
  };
  export function http(
    config: HttpTriggerConfig,
  ): TriggerDefinition<HttpTrigger, HttpTriggerConfig> {
    return {
      type: 'http',
      config: config,
      policies: config.policies ?? [],
    };
  }
  export const httpConfigSchema = createSchema<HttpTriggerConfig>({
    policies: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    method: {
      type: 'string',
      enum: ['get', 'post', 'put', 'delete', 'patch'],
      required: true,
      errorMessage: {
        enum: 'Method must be one of the following: "get", "post", "put", "delete", "patch"',
        type: 'Method must be one of the following: "get", "post", "put", "delete", "patch"',
        required: 'Missing method',
      },
    },
    path: {
      type: 'string',
      required: true,
      pattern: '^/.*',
      errorMessage: {
        pattern: 'Path must start with "/"',
        required: 'Missing path',
      },
    },
  });

  http.rule = ((ast, service) => {
    const reports: diagnostic.Diagnostic[] = [];
    if (!Checker.isCallExpression(ast.node)) {
      return reports;
    }
    const [config] = ast.node.arguments;

    if (Checker.isObjectExpression(config)) {
      const errors: any[] =
        getErrors(() => validateInput(httpConfigSchema, config as any)) ?? [];
      Object.values(errors).forEach((message) => {
        reports.push({
          message,
          span: (ast.node as any).span,
          node: ast.node as any,
          severity: 'error',
        });
      });

      if (!errors.length) {
        // if config is valid
        const relatedFeature = service.getFeature(ast as any);
        const relatedWorkflow = service.getWorkflow(ast as any);
        if (!relatedWorkflow || !relatedFeature) {
          return [];
        }
        const isDuplicate = service.duplicateWorkflowTriggerConfig(
          relatedWorkflow,
          (otherWorkflow, feature) => {
            if (otherWorkflow.trigger.type !== 'http') {
              return false;
            }
            const dub =
              normalizeWorkflowPath({
                featureName: feature.name,
                workflowTag: otherWorkflow.tag,
                workflowPath: otherWorkflow.trigger.config['path'] as string,
                workflowMethod: otherWorkflow.trigger.config['method'] as string,
              }) ===
              normalizeWorkflowPath({
                featureName: relatedFeature.name,
                workflowTag: relatedWorkflow.tag,
                workflowPath: config['path'] as string,
                workflowMethod: config['method'] as string,
              });

            if (dub) {
              console.log('dub', dub);
            }
            return dub;
          },
        );

        if (isDuplicate) {
          reports.push({
            message: 'Duplicate trigger config',
            span: ast.node.span,
            node: ast.node as any,
            severity: 'error',
          });
        }
      }
    } else {
      reports.push({
        message: 'Missing trigger config',
        span: (ast.node as any).span,
        node: ast.node as any,
        severity: 'error',
      });
    }
    return reports;
  }) as RuleFn;
}
export namespace trigger {
  export function github<EventName extends EmitterWebhookEventName, Y>(config: {
    event: EventName;
    policies?: string[];
    mapper?: (event: EmitterWebhookEvent<EventName>) => Y;
  }): TriggerDefinition<EmitterWebhookEvent<EventName>, Y> {
    const inputs: Record<string, { value: string }> = {};
    if (config.mapper) {
      const guard = config.mapper.toString();
      const project = new Project({
        useInMemoryFileSystem: true,
      });
      const sourceFile = project.createSourceFile(
        'index.ts',
        `const mapFn = ${guard}`,
      );

      const triggerIdentifierText = 'trigger';

      const guardFunction = sourceFile
        .getVariableDeclarationOrThrow('mapFn')
        .getInitializerIfKindOrThrow(SyntaxKind.ArrowFunction);

      const returnExpr =
        guardFunction.getFirstChildByKind(SyntaxKind.ParenthesizedExpression) ??
        guardFunction.getFirstDescendantByKindOrThrow(
          SyntaxKind.ReturnStatement,
        );

      const returnObjExpr = returnExpr.getExpressionIfKindOrThrow(
        SyntaxKind.ObjectLiteralExpression,
      );
      returnObjExpr.getProperties().forEach((prop) => {
        const propAssignment = prop.asKindOrThrow(
          SyntaxKind.PropertyAssignment,
        );
        const propName = propAssignment.getName();
        const propValue = propAssignment.getInitializerOrThrow();
        inputs[propName] = {
          value: propValue
            .getFullText()
            .replaceAll(`${triggerIdentifierText}.`, ''),
        };
      });
    }

    return {
      type: 'github-trigger',
      config: config,
      policies: config.policies ?? [],
      inputs: inputs,
    };
  }
  github.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
}

// trigger.event = (config: { name: string }): TriggerDefinition => {
//   return {
//     type: 'event',
//     config: config,
//   };
// };

// trigger.queue = (config: { name: string }): TriggerDefinition => {
//   return {
//     type: 'queue',
//     config: config,
//   };
// };
export function policy(rule: string) {
  return rule;
}
function isLiteralObject(obj: unknown): obj is Record<string, unknown> {
  return obj !== null && typeof obj === 'object' && obj.constructor === Object;
}

function definePolicy(rule: string) {
  return (name: string) => rule;
}

export namespace policy {
  export function fromConfig(type: string, ...args: unknown[]): Policy {
    const parts = type.split('.');
    let impl = parts.length ? (policy as any) : definePolicy;
    while (parts.length) {
      impl = (impl as any)[parts.shift() as any];
    }
    if (impl) {
      return impl(...args);
    }
    if (type.endsWith('.rule')) {
      const reports: diagnostic.Diagnostic[] = [];
      return reports as never;
    }
    throw new Error(`Unknown policy type: ${type}`);
  }
  export function authenticated(): Policy {
    // @trigger:subject.authenticated
    // @trigger:subject.name
    // @trigger:subject.claims
    // @trigger:subject.id
    // @trigger:anonymous
    // @trigger:authenticated
    // TODO: what about making the identity-auth extensions
    // parse this input?
    // return '@subject:authenticated';
    // FIXME: this have hard dependency on hono
    // we need a way to make the trigger return the types or the function signature
    return (name) => `
    import { Context } from 'hono';
    import { verifyToken } from './subject';

    export async function ${name}(context: Context) {
      return verifyToken(context.req.header('Authorization'));
    }
  `;
  }

  function http(): Policy {
    return () => '';
  }

  export function github<EventName extends EmitterWebhookEventName>(config: {
    events?: EventName[];
    guard?: (event: EmitterWebhookEvent<EventName>) => boolean;
  }): Policy {
    if (!config.events || !config.events.length || !config.guard) {
      return () => '';
    }
    const project = new Project({
      useInMemoryFileSystem: true,
    });

    let body = 'return true';
    if (config.guard) {
      const guard = config.guard.toString();
      const sourceFile = project.createSourceFile(
        'guard.ts',
        `const guard = ${guard}`,
      );
      const guardFunction = sourceFile
        .getVariableDeclarationOrThrow('guard')
        .getInitializerOrThrow() as morph.ArrowFunction;
      // this fix allow user to return the value directly
      // or within a block.
      const isBlock = guardFunction.getBody().isKind(SyntaxKind.Block);
      body = isBlock
        ? guardFunction.getBodyText()
        : `return ${guardFunction.getBodyText()}`;
    }

    return (name) => `
    import { EmitterWebhookEvent } from '@octokit/webhooks';
    import { isEventOfType } from '../core/github-webhooks';

    export async function ${name}(event: EmitterWebhookEvent) {
      if(isEventOfType(event, ${(config.events ?? []).map((it) => `'${it}'`).join(', ')})) {
        ${body}
      }
      return false;

  }`;
  }

  github.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
}

// policy.guest = () => {
//   //
// }

policy.unstable_country = (country: string) => {
  // FIXME: but how I can still have the same endpoint but with different logic
  // for different countries?

  // using where expression here would be better
  // where('@trigger:context.country', 'equals', country)
  return `@trigger:context.country === '${country}'`;
};
