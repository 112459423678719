// @subject:user.id = @tables:posts(@trigger:path.id).userId
import { v4 } from 'uuid';

import { parseDsl } from './input-parser';
import type {
  Arg,
  Binary,
  Call,
  Expression,
  Identifier,
  Namespace,
  PropertyAccess,
  StringLiteral,
} from './token';
import { Visitor, typeChecker } from './token';

export * from './input-parser';
export * from './prompt-parser';
export * from './sqlite.visitor';
export * from './token';

type ReturnValue = any;
class RuleDecomposerVisitor extends Visitor<ReturnValue> {
  override visitArg(node: Arg): ReturnValue {
    const name = node.name.accept(this);
    if (typeChecker.isNamespace(node.value)) {
      const value = node.value.accept(this);
      return `${name}: ${value.id}`;
    }
    const value = node.value.accept(this);
    return `${name}: ${value.id}`;
  }
  namespaces: Record<string, string> = {};
  override visitBinary(node: Binary): ReturnValue {
    const left = node.left.accept(this);
    const right = node.right.accept(this);
  }
  override visitCall(node: Call): ReturnValue {
    const name = node.name.accept(this);
    const args = node.args.map((arg) => {
      return arg.accept(this);
    });
    return `${name}(${args.join(', ')})`;
  }

  override visitPropertyAccess(node: PropertyAccess): string {
    return `${node.name.accept(this)}.${node.expression.accept(this)}`;
  }

  override visitNamespace(node: Namespace): ReturnValue {
    const id = v4();
    const name = `@${node.name.accept(this)}:${node.expression.accept(this)}`;
    this.namespaces = {
      ...this.namespaces,
      [id]: name,
    };
    return { id, name };
  }

  override visitIdentifier(node: Identifier): string {
    return node.value;
  }

  override visitStringLiteral(node: StringLiteral): string {
    return `'${node.value}'`;
  }

  override visit(node: Expression): Record<string, string> {
    node.accept(this);
    return this.namespaces;
  }
}
export function decomposeVisitor(input: string) {
  const visitor = new RuleDecomposerVisitor();
  return visitor.visit(parseDsl(input));
}
