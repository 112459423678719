export function output(returnStr: string): string {
  return `export default ()=>{
    ${returnStr}
  }`;
}

output.forbidden = () => {
  return `export default ()=>{
	throw new Error('Forbidden');
  }`;
};

output.empty = () => {
  return `export default ()=>{
	return {};
  }`;
};
