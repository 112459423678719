import { type QueryBuilder, Visitor } from '../expression';

type JsVisitorType = string;

export class JsVisitor extends Visitor<JsVisitorType> {
  public visitCombinator(expr: QueryBuilder.Combinator, context: any): string {
    // const left = expr.left.accept(this, context);
    // const right = expr.right.accept(this, context);
    // switch (expr.operator) {
    //   case 'and':
    //     return `(${left} && ${right})`;
    //   case 'or':
    //     return `(${left} || ${right})`;
    // }
    return '';
  }

  public visitListExpr(expr: QueryBuilder.ListExpr, context: any): string {
    const list = expr.value.map((item) => item.accept(this, context));
    return list.join(',');
  }

  public visitQuerySelectExpr(
    expr: QueryBuilder.QuerySelectExpr,
    context: any,
  ): string {
    return '';
  }

  public visitGroupExpr(expr: QueryBuilder.GroupExpr, context: any): string {
    throw new Error('Method not implemented.');
  }
  public visitDateLiteralExpr(
    expr: QueryBuilder.Literal<'date'>,
    context: { raw?: boolean } = {},
  ): any {
    return expr.value;
  }
  constructor(private _rootExpr: QueryBuilder.GroupExpr) {
    super();
  }

  public visitBinaryExpr(
    expr: QueryBuilder.BinaryExpression,
    context: any,
  ): string {
    const left = expr.left.accept(this, context);
    const right = expr.right.accept(this, context);
    switch (expr.operator) {
      case '===':
        return `(${left} === ${right})`;
      case 'like':
        switch (context.pattern) {
          case '%{}':
            return `${left}.endsWith(${right})`;
          case '{}%':
            return `${left}.startsWith(${right})`;
          case '%{}%':
            return `${left}.includes(${right})`;
          default:
            throw new Error(
              `Like operator not supported. pattern: ${context.pattern}`,
            );
        }
        break;

      default:
        throw new Error(
          `Expression is not supported. operator: ${expr.operator}`,
        );
    }
  }

  public visitNumericLiteralExpr(
    expr: QueryBuilder.Literal<'numeric'>,
  ): string {
    return `${expr.value}`;
  }

  public visitNullLiteralExpr(expr: QueryBuilder.Literal<'null'>): string {
    return `${expr.value}`;
  }

  public visitBooleanLiteralExpr(
    expr: QueryBuilder.Literal<'boolean'>,
  ): string {
    return `${expr.value}`;
  }

  public visitStringLiteralExpr(expr: QueryBuilder.Literal<'string'>): string {
    return `"${expr.value}"`;
  }

  public visitIdentifier(expr: QueryBuilder.Identifier): string {
    return `${expr.value}`;
  }

  public execute(): string {
    return this.visitGroupExpr(this._rootExpr, {});
  }
}
