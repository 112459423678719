import { isNotEmpty, isString } from 'class-validator';
import { get } from 'lodash';
import { join, normalize } from 'path';
import retry from 'retry';
import { snakecase, spinalcase } from 'stringcase';

export function orThrow<T>(fn: () => T, message?: string): NonNullable<T> {
  const result = fn() as any;
  if ([undefined, null].includes(result)) {
    const error = new Error(message);
    Error.captureStackTrace(error, orThrow);
    throw error;
  }
  return result;
}
export function isNullOrUndefined(value: any): value is undefined | null {
  return value === undefined || value === null;
}
export function notNullOrUndefined<T>(
  value: T,
): value is Exclude<T, null | undefined> {
  return !isNullOrUndefined(value);
}

export function isNotEmptyString(value: string) {
  return isNotEmpty(value) && isString(value);
}

export function upsert<T extends { id: string }>(
  array: T[],
  id: string,
  insert: (current: T, isNew: boolean) => T,
) {
  const [index, item] = byId<T>(array, id);
  if (item) {
    array[index] = insert(item, false);
    return array;
  } else {
    return [...array, insert({ id } as T, true)];
  }
}
export async function upsertAsync<T extends { id: string }>(
  array: T[],
  id: string,
  insert: (current: T) => Promise<T> | T,
): Promise<T[]> {
  const [index, item] = byId<T>(array, id);
  if (item) {
    array[index] = await insert(item);
    return array;
  } else {
    return [...array, await insert({ id } as T)];
  }
}

export function byId<T extends { id: string }>(
  array: T[],
  id: string,
): [number, T | undefined] {
  const index = array.findIndex((it) => it.id === id);
  return [index, array[index]];
}

const removeEmpty = (obj: any) => {
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]) && !Array.isArray(obj[key]))
      newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export function partition<T>(array: T[], by: (item: T) => string) {
  const result: Record<string, T[]> = {};

  for (const item of array) {
    const splitBy = by(item);
    if (!result[splitBy]) {
      result[splitBy] = [];
    }
    result[splitBy].push(item);
  }

  return result;
}
export function assertNotNullOrUndefined<T>(
  value: T,
  debugLabel: string,
): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(`${debugLabel} is undefined or null.`);
  }
}
export type Omit<T, P> = Pick<
  T,
  {
    [key in keyof T]: T[key] extends P ? never : key;
  }[keyof T]
>;

export async function profile<T>(
  {
    label,
    seconds = false,
  }: {
    label: string;
    seconds?: boolean;
    logData?: any;
  },
  fn: () => Promise<T> | T,
): Promise<T> {
  const startTime = performance.now();

  try {
    return await fn(); // Await the function if it's a promise
  } finally {
    const endTime = performance.now();
    const time = endTime - startTime;
    const formattedTime = seconds ? (time / 1000).toFixed(6) : time.toFixed(6);
    const timeUnit = seconds ? 'seconds' : 'milliseconds';

    console.log(`Execution time => [${label}]: ${formattedTime} ${timeUnit}`);
  }
}
const colors = {
  green: (message: string) => `\x1b[32m${message}\x1b[0m`,
  blue: (message: string) => `\x1b[34m${message}\x1b[0m`,
  magenta: (message: string) => `\x1b[35m${message}\x1b[0m`,
};

export function createRecorder(
  options: {
    label?: string;
    seconds?: boolean;
    verbose?: boolean;
  } = { seconds: false },
) {
  const startedAt = performance.now();
  console.log(colors.green(`Recording started => [${options.label}]`));
  const operations = new Map<string, number>();
  return {
    record: (label: string) => {
      operations.set(label, performance.now());
      if (options.verbose) {
        console.log(
          colors.blue(`Recording => [${options.label ? `${options.label} => ` : ''}${label}]
        `),
        );
      }
    },
    recordEnd: (label: string, result?: unknown) => {
      const endTime = performance.now();
      const time = endTime - (operations.get(label) as number);
      const formattedTime = options.seconds
        ? (time / 1000).toFixed(6)
        : time.toFixed(6);
      const timeUnit = options.seconds ? 'seconds' : 'milliseconds';
      console.log(
        colors.blue(
          `Execution time => [${options.label ? `${options.label} => ` : ''}${label}]: ${formattedTime} ${timeUnit}`,
        ),
        ...[result].filter((item) => typeof item !== 'undefined'),
      );
      operations.delete(label);
    },
    end: () => {
      const endTime = performance.now();
      const time = endTime - startedAt;
      const lastEntry = Array.from(operations.entries()).at(-1);

      if (lastEntry) {
        // log missing end

        const [label, start] = lastEntry;
        const time = performance.now() - start;
        const formattedTime = options.seconds
          ? (time / 1000).toFixed(6)
          : time.toFixed(6);
        const timeUnit = options.seconds ? 'seconds' : 'milliseconds';
        console.log(
          colors.magenta(
            `Recording Total time => [${options.label ? `${options.label} => ` : ''}${label}]: ${formattedTime} ${timeUnit}`,
          ),
        );
        operations.delete(label);
      }

      const formattedTime = options.seconds
        ? (time / 1000).toFixed(6)
        : time.toFixed(6);
      const timeUnit = options.seconds ? 'seconds' : 'milliseconds';
      console.log(
        colors.magenta(
          `Recording end => [${options.label}]: ${formattedTime} ${timeUnit}`,
        ),
      );
    },
  };
}

export function applyCondition(
  condition: {
    operator: 'equal' | 'not_equal';
    value: string;
    input: string;
    then: any;
    else: any;
  },
  context: ResolveContext,
) {
  const input = resolveContextKey(condition.input, context);
  switch (condition.operator) {
    case 'equal':
      return input === condition.value ? condition.then : condition.else;
    case 'not_equal':
      return input !== condition.value ? condition.then : condition.else;
    default:
      throw new Error(`Unknown operator ${condition.operator}`);
  }
}

export function resolveContextKey(
  key: string,
  details: ResolveContext,
): string {
  const [source, path] = key.split('.');
  if (source === 'self') {
    return get(details.self, path);
  } else if (source === 'context') {
    return get(details.context, path);
  }
  return key;
}

export interface ResolveContext {
  self: Record<string, any>;
  context: Record<string, any>;
}

export function buildUrl(
  url: string,
  details: ResolveContext,
  binding: Record<string, any>,
): { url: string; params: string[] } {
  {
    const variables = url.split(/\/([^\/]+)/).filter((x) => x.startsWith(':'));

    // no variables in url
    if (!variables.length || !details) {
      return { url, params: [] };
    }

    const params = variables.reduce<Record<string, any>>((acc, variable) => {
      const key = variable.slice(1);
      return {
        ...acc,
        [key]: resolveContextKey(binding[key], details),
      };
    }, {});
    return {
      url: url,
      params: Object.values(params),
    };
  }
}

export function isResolvable(maybeResolvable: any) {
  if (!maybeResolvable) {
    return false;
  }

  if (Array.isArray(maybeResolvable)) {
    return true;
  }

  if (maybeResolvable.url) {
    return true;
  }

  return false;
}

export function isCondition<T>(obj: any): obj is T {
  if (!obj || typeof obj === 'string' || Array.isArray(obj)) return false;
  if ('input' in obj && 'operator' in obj && 'value' in obj) {
    return true;
  }
  return false;
}

export function parseDetails(details: string | undefined, path?: string): any {
  const parsed = JSON.parse(details ?? '{}');
  return path ? get(parsed, path, {}) : parsed;
}

export const logMe = (object: any) =>
  console.dir(object, {
    showHidden: false,
    depth: Infinity,
    maxArrayLength: Infinity,
    colors: true,
  });

type InferValue<T> = T extends Record<string, infer U> ? U : never;
export function toLiteralObject<T extends Record<string, any>>(
  obj: T,
  accessor: (value: InferValue<T>) => string = (value) => value,
) {
  return `{${Object.keys(obj)
    .map((key) => `${key}: ${accessor(obj[key])}`)
    .join(', ')}}`;
}
export function addLeadingSlash(path: string) {
  return normalize(join('/', path));
}

export function removeTrialingSlash(path: string) {
  return path.replace(/\/$/, '');
}

export function retryPromise<T>(promise: () => Promise<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const operation = retry.operation({
      factor: 2,
      randomize: true,
      minTimeout: 1000,
      maxTimeout: 2000,
    });

    operation.attempt(async (currentAttempt) => {
      try {
        const result = await promise();
        resolve(result);
      } catch (error) {
        if (!operation.retry(error as any)) {
          reject(error);
        }
      }
    });
  });
}
export function uniquify<T>(data: T[], accessor: (item: T) => unknown): T[] {
  return [...new Map(data.map((x) => [accessor(x), x])).values()];
}

export function toRecord<T>(
  array: T[],
  config: {
    accessor: (item: T) => string;
    map: (item: T) => any;
  },
) {
  return array.reduce<Record<string, T>>((acc, item) => {
    return {
      ...acc,
      [config.accessor(item)]: config.map(item),
    };
  }, {});
}

export function hasProperty<
  T extends Record<string, unknown>,
  K extends keyof T,
>(obj: T, key: K): obj is T & Record<K, unknown> {
  if (typeof obj !== 'object') {
    return false;
  }
  return key in obj;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function safeFail<T>(fn: () => T, defaultValue: T): typeof defaultValue {
  try {
    return fn();
  } catch (error) {
    return defaultValue;
  }
}

export async function extractError<T>(fn: () => Promise<T>) {
  try {
    return {
      value: await fn(),
      error: undefined,
    };
  } catch (error) {
    return { error } as { error: unknown; value: T };
  }
}

function toCurlyBraces(path: string) {
  return path
    .replace(':', '$:')
    .split('$')
    .map((it) => {
      if (!it.startsWith(':')) {
        return it.split('/').filter(Boolean).join('/');
      }
      const [param, ...rest] = it.split('/');
      return [`{${param.slice(1)}}`, ...rest].join('/');
    })
    .join('/');
}

export function normalizeWorkflowPath(config: {
  featureName: string;
  workflowTag: string;
  workflowPath: string;
  workflowMethod?: string;
}) {
  const path = removeTrialingSlash(
    addLeadingSlash(
      join(
        spinalcase(config.featureName),
        snakecase(config.workflowTag),
        toCurlyBraces(config.workflowPath),
      ),
    ),
  );
  return config.workflowMethod ? `${config.workflowMethod} ${path}` : path;
}

const pool: Record<string, Worker> = {};
export function runWorker<T>(
  publicPath: string,
  message: { type: string; payload: unknown },
  options: WorkerOptions & {
    terminateImmediately?: boolean;
  } = {
    type: 'module',
    terminateImmediately: false,
  },
) {
  let worker: Worker;
  if (options.terminateImmediately) {
    worker = new Worker(publicPath, options);
  } else {
    worker = pool[publicPath] ??= new Worker(publicPath, options);
  }
  // const worker =
  //   process.env['NODE_ENV'] === 'development'
  //     ? new Worker(publicPath, options)
  //     : pool[publicPath];

  const defer = new Promise<T>((resolve, reject) => {
    worker.onmessage = (e) => {
      if (options.terminateImmediately) {
        worker.terminate();
      }

      if ('error' in e.data) {
        reject(e.data.error);
        console.error(e.data.error);
      } else {
        resolve(e.data.data);
      }
    };
    worker.onerror = (e) => {
      if (options.terminateImmediately) {
        worker.terminate();
      }
      reject(e.error);
    };
  });

  worker.postMessage(message);

  return defer;
}

export function removeDuplicates<T>(
  data: T[],
  accessor: (item: T) => T[keyof T],
): T[] {
  return [...new Map(data.map((x) => [accessor(x), x])).values()];
}