// @subject:user.id = @tables:posts(@trigger:path.id).userId
import type { ClassType } from 'tiny-injector/Types';

import { toSimple } from './sqlite.visitor';
import type { Expression, Token, TokenType } from './token';
import {
  Arg,
  Binary,
  Call,
  Identifier,
  Namespace,
  PropertyAccess,
  StringLiteral,
} from './token';
import { tokeniser } from './tokeniser';

const grammars = `
  <expression> ::= <namespace> <property-access>
  <namespace> ::= @<identifier>:
  <property-access> ::= <property-access> . <identifier> | <identifier>
  <call> ::= <function> <arg>
  <function> ::= <identifier>
  <arg> ::= <namespace> | <identifier>
  <arg> ::= <namespace> | <identifier>

  <identifier> ::= <letter> | <identifier> <char>
  <char> ::= <letter> | <digit> | "_"
  <letter> ::= "a" | "b" | ... | "z" | "A" | "B" | ... | "Z"
  <digit> ::= "0" | "1" | ... | "9"
`;

export function parseInput(input: string) {
  return toSimple(input);
}

export class ParserTokens {
  currentIdx = 0;
  tokens: Token[] = [];

  constructor(tokens: Token[]) {
    this.tokens = tokens;
  }

  get peek() {
    return this.tokens[this.currentIdx];
  }
  get lookahead() {
    return this.tokens[this.currentIdx + 1];
  }

  get lookbehind() {
    return this.tokens[this.currentIdx - 1];
  }

  isAtEnd() {
    return this.check('EOF');
  }

  match(...types: TokenType[]) {
    if (this.isAtEnd()) return false;

    if (this.check(...types)) {
      this.advance();
      return true;
    }
    return false;
  }

  consume(type: TokenType, message: string) {
    if (this.check(type)) {
      return this.advance();
    }
    const error = new Error(
      `${message} at ${this.currentIdx} Found ${this.peek.type}`,
    );
    Error.captureStackTrace(error, this.consume);
    throw error;
  }

  check(...tokens: TokenType[]): boolean {
    return tokens.includes(this.peek.type);
  }

  advance() {
    return this.tokens[++this.currentIdx];
  }

  retreat() {
    return this.tokens[--this.currentIdx];
  }

  reset() {
    this.currentIdx = 0;
  }

  slice() {
    return this.tokens.slice(this.currentIdx);
  }
}

class DSLParser extends ParserTokens {
  constructor(public input: string) {
    super(tokeniser(input));
  }

  subparsing(parserType: ClassType<NamespaceParser>) {
    const parser = new parserType(this.slice());
    const { expression, index } = parser.subparse();
    this.currentIdx += index;
    return expression;
  }

  #equal(): Expression {
    const expression = this.subparsing(NamespaceParser);
    if (this.match('EQUALS') || this.match('NOT_EQUALS')) {
      const operator = new Identifier(this.lookbehind.value);
      const right = this.#expression();
      return new Binary(operator, expression, right);
    }
    return expression;
  }

  #expression(): Expression {
    const expression = this.#equal();
    return expression;
  }

  parse() {
    const result = this.#expression();
    this.consume('EOF', 'Expecting EOF');
    this.reset();
    return result;
  }
}

export function parseDsl(input: string) {
  const parser = new DSLParser(input);
  return parser.parse();
}

export class NamespaceParser extends ParserTokens {
  #primary(): Expression {
    if (this.match('STRING')) {
      return new StringLiteral(this.lookbehind.value);
    }

    if (this.match('IDENTIFIER')) {
      return new Identifier(this.lookbehind.value);
    }

    if (this.match('AT')) {
      // const namespace = this.#primary() as Identifier;
      const namespace = new Identifier(this.peek.value);
      this.consume('IDENTIFIER', 'Expecting identifier');
      this.consume('COLON', 'Expecting :');
      return new Namespace(namespace, this.#expression());
    }

    const token = this.peek;
    const error = new Error(`Unexpected token ${token.value}`);
    // Error.captureStackTrace(error, this.#primary);
    throw error;
  }

  #call(): Expression {
    // FIXME: throw error if function is not an identifier
    const expression = this.#primary() as Identifier;
    if (this.match('OPEN_PAREN')) {
      const args: Arg[] = [];
      do {
        const name = this.#primary() as Identifier;
        this.consume('COLON', 'Expecting :');
        const value = this.#expression();
        args.push(new Arg(name, value));
      } while (this.match('COMMA'));
      this.consume('CLOSE_PAREN', 'Expecting )');
      return new Call(expression, args);
    }
    return expression;
  }

  #propertyAccess(): Expression {
    let expression = this.#call();
    while (this.match('DOT')) {
      const primary = this.#primary();
      expression = new PropertyAccess(expression, primary);
    }
    return expression;
  }

  #expression(): Expression {
    const expression = this.#propertyAccess();
    return expression;
  }

  subparse() {
    // this.consume('AT', 'Expecting @');
    const result = this.#expression();
    return {
      expression: result,
      index: this.currentIdx,
    };
  }

  parse() {
    // this.consume('AT', 'Expecting @');
    const result = this.#expression();
    this.consume('EOF', 'Expecting EOF');
    this.reset();
    return result;
  }
}
