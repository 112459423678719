import type {
  ArrayExpression,
  BigIntLiteral,
  BlockStatement,
  BooleanLiteral,
  CallExpression,
  ExportAllDeclaration,
  ExportDeclaration,
  ExportDefaultDeclaration,
  ExportDefaultExpression,
  ExportNamedDeclaration,
  Expression,
  HasSpan,
  Identifier,
  Import,
  KeyValueProperty,
  MemberExpression,
  ModuleItem,
  NumericLiteral,
  ObjectExpression,
  PrivateName,
  Property,
  PropertyName,
  Span,
  SpreadElement,
  StringLiteral,
  Super,
  TsAsExpression,
  UnaryExpression,
} from '@swc/wasm-web';
import initSync, { parseSync } from '@swc/wasm-web';

namespace checker {
  export function isCallExpression(
    node?: Expression | BlockStatement,
    name?: string,
  ): node is CallExpression {
    if (!node) {
      return false;
    }

    const isCallExpr = node.type === 'CallExpression';
    if (!isCallExpr) {
      return false;
    }
    if (!name) {
      return true;
    }

    if (node.callee.type === 'MemberExpression') {
      return (
        node.callee.property.type === 'Identifier' &&
        node.callee.property.value === name
      );
    }
    return node.callee.type === 'Identifier' && node.callee.value === name;
  }

  export function isObjectExpression(
    node: Expression,
  ): node is ObjectExpression {
    return node.type === 'ObjectExpression';
  }

  export function isKeyValueProperty<T extends Expression>(
    node: Property | SpreadElement,
    valueType?: T['type'],
    keyName?: string,
  ): node is KeyValueProperty & { value: T } {
    if (node.type !== 'KeyValueProperty') {
      return false;
    }
    if (!valueType) {
      return true;
    }
    const sameType = node.value.type === valueType;
    if (!sameType) {
      return false;
    }
    if (!keyName) {
      return true;
    }
    return isIdentifier(node.key, keyName);
  }

  export function isStringLiteral(node: Expression): node is StringLiteral {
    return node.type === 'StringLiteral';
  }

  export function isNullLiteral(node: Expression): node is StringLiteral {
    return node.type === 'NullLiteral';
  }

  export function isPrimitive(
    node: Expression,
  ): node is StringLiteral | BooleanLiteral | NumericLiteral | BigIntLiteral {
    if (
      node.type === 'StringLiteral' ||
      node.type === 'BooleanLiteral' ||
      node.type === 'NumericLiteral' ||
      node.type === 'BigIntLiteral'
    ) {
      return true;
    }
    return false;
  }

  export function isIdentifier(
    node?: PropertyName | PrivateName | Expression | Super | Import,
    name?: string,
  ): node is Identifier {
    if (!node) {
      return false;
    }
    const isIdentifier = node.type === 'Identifier';
    if (!isIdentifier) {
      return false;
    }
    if (!name) {
      return true;
    }
    return node.value === name;
  }

  export function isMemberExpression(
    node?: Expression | Super | Import,
    name?: string,
  ): node is MemberExpression {
    if (!node) {
      return false;
    }
    const isMemberExpr = node.type === 'MemberExpression';
    if (!isMemberExpr) {
      return false;
    }
    if (!name) {
      return true;
    }
    return isIdentifier(node.property, name);
  }

  export function isArrayExpression(node: Expression): node is ArrayExpression {
    return node.type === 'ArrayExpression';
  }
}

export namespace diagnostic {
  export interface Diagnostic {
    message: string | Record<string, string> | string[];
    node: Expression;
    span?: Span;
    severity: 'error' | 'warning' | 'info';
  }
}

export namespace Checker {
  export function isPrimitive(
    value: any,
  ): value is string | number | boolean | bigint {
    return value !== Object(value);
  }

  export function isCallExpression(value: any): value is CallExpression {
    return (
      !isPrimitive(value) &&
      typeof value === 'object' &&
      'caller' in value &&
      'arguments' in value
    );
  }

  export function isObjectExpression(
    value: any,
  ): value is Record<string, Expression> {
    return (
      !isCallExpression(value) && value !== null && typeof value === 'object'
    );
  }

  export function isArrayExpression(value: any): value is Array<Expression> {
    return Array.isArray(value);
  }

  export type Literal = string | number | bigint | boolean | null;

  export type Expression =
    | CallExpression
    | Literal
    | {
        [key in string]: Expression;
      }
    | Array<Expression>;
  // | Record<string, Expression>;
  // | Expression[];

  export interface CallExpression extends HasSpan {
    caller: string;
    arguments: Expression[];
  }

  export interface ProjectImport {
    isTypeOnly: boolean;
    moduleSpecifier: string;
    defaultImport: string | undefined;
    namedImports: NamedImport[];
    namespaceImport: string | undefined;
  }
  export interface NamedImport {
    name: string;
    alias?: string;
    isTypeOnly: boolean;
  }

  export type Exports =
    | ExportDefaultExpression
    | ExportDefaultDeclaration
    | ExportNamedDeclaration
    | ExportAllDeclaration
    | ExportDeclaration;
}
const isWorker = () => {
  return typeof (global as any).importScripts === 'function';
};
let sourceCode: string | undefined;

function isRecord(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null;
}

function isSpan(obj: unknown): obj is { start: number; end: number } {
  return (
    typeof obj === 'object' && obj !== null && 'start' in obj && 'end' in obj
  );
}

function adjustOffsetOfAst(obj: unknown, startOffset: number) {
  if (Array.isArray(obj)) {
    obj.forEach((item) => adjustOffsetOfAst(item, startOffset));
  } else if (isRecord(obj)) {
    Object.entries(obj).forEach(([key, value]) => {
      if (key === 'span' && value && isSpan(value)) {
        const span = value;
        span.start -= startOffset;
        span.end -= startOffset;
      } else {
        adjustOffsetOfAst(obj[key], startOffset);
      }
    });
  }
}
const init = initSync(
  'https://cdn.jsdelivr.net/npm/@swc/wasm-web@1.7.3/wasm_bg.wasm',
);

async function parseCode(code: string) {
  await init;

  if (typeof code !== 'string' || !code.trim()) {
    return null;
  }

  const val = parseSync(code, {
    syntax: 'typescript',
    decorators: false,
    comments: false,
    dynamicImport: false,
    script: false,
    tsx: false,
    target: 'es2022',
  });
  {
    // FIXME: let go of this as it's creating more bugs
    // In the editor side just count the whitespaces and and them to the start of span
    // swc doesn't count empty width so it'll always start counting from a non-empty character
    // val.span.start = 1;
    // val.span.end = code.length;

    adjustOffsetOfAst(val, val.span.start);
  }
  return val;
}

export async function parseDeclarative(code: string) {
  sourceCode = code;
  const val = await parseCode(code);
  if (!val) {
    return null;
  }

  const importStmt = val.body.filter((it) => it.type === 'ImportDeclaration');

  const projectExpr = val.body.find(
    (it) => it.type === 'ExportDefaultExpression',
  );

  if (!projectExpr) {
    return null;
  }

  if (!checker.isCallExpression(projectExpr.expression, 'project')) {
    return null;
  }

  return {
    imports: importStmt.map(
      (it) =>
        ({
          isTypeOnly: it.typeOnly,
          moduleSpecifier: it.source.value,
          defaultImport: it.specifiers.find(
            (sp) => sp.type === 'ImportDefaultSpecifier',
          )?.local.value,
          namespaceImport: it.specifiers.find(
            (sp) => sp.type === 'ImportNamespaceSpecifier',
          )?.local.value,
          namedImports: it.specifiers
            .filter((sp) => sp.type === 'ImportSpecifier')
            .map(
              (sp) =>
                ({
                  name: sp.imported ? sp.imported.value : sp.local.value,
                  alias: sp.imported ? sp.local.value : undefined,
                  isTypeOnly: sp.isTypeOnly,
                }) satisfies Checker.NamedImport,
            ),
        }) satisfies Checker.ProjectImport,
    ) as Checker.ProjectImport[],
    project: resolveCallExpression(projectExpr.expression),
  };
}

function resolveAsExpression(node: TsAsExpression) {
  const args: any[] = [];

  if (checker.isNullLiteral(node.expression)) {
    args.push(null);
  }
  if (checker.isPrimitive(node.expression)) {
    args.push(node.expression.value);
  }
  if (checker.isIdentifier(node.expression)) {
    args.push(node.expression.value);
  }
  if (checker.isObjectExpression(node.expression)) {
    args.push(resolveObjectExpression(node.expression));
  }
  if (checker.isCallExpression(node.expression)) {
    args.push(resolveCallExpression(node.expression));
  }
  if (checker.isMemberExpression(node.expression)) {
    // join the member expression here since it's most likely a path
    args.push(resolveMemberExpression(node.expression, []).join('.'));
  }
  if (node.expression.type === 'TsAsExpression') {
    args.push(resolveAsExpression(node.expression));
  }
  return args;
}

function resolveCallExpression(node: CallExpression): Checker.CallExpression {
  const args: any[] = [];
  for (const arg of node.arguments) {
    if (checker.isNullLiteral(arg.expression)) {
      args.push(null);
      continue;
    }
    if (arg.expression.type === 'UnaryExpression') {
      args.push(resolveUnaryExpression(arg.expression));
      continue;
    }
    if (checker.isPrimitive(arg.expression)) {
      args.push(arg.expression.value);
      continue;
    }
    if (checker.isIdentifier(arg.expression)) {
      args.push(arg.expression.value);
    }

    if (checker.isObjectExpression(arg.expression)) {
      args.push(resolveObjectExpression(arg.expression));
    }
    if (checker.isCallExpression(arg.expression)) {
      args.push(resolveCallExpression(arg.expression));
    }
    if (checker.isMemberExpression(arg.expression)) {
      args.push(resolveMemberExpression(arg.expression, []));
    }
    if (arg.expression.type === 'ArrowFunctionExpression') {
      if (sourceCode) {
        args.push(
          sourceCode.slice(arg.expression.span.start, arg.expression.span.end),
        );
      }
    }
    if (arg.expression.type === 'TsAsExpression') {
      args.push(resolveAsExpression(arg.expression));
    }
  }

  let calleeName = '';

  if (checker.isMemberExpression(node.callee)) {
    // const [, ...actionPath] = resolveMemberExpression(node.callee, []);
    const [...actionPath] = resolveMemberExpression(node.callee, []);
    calleeName = actionPath.join('.');
  }
  if (checker.isIdentifier(node.callee)) {
    calleeName = node.callee.value;
  }

  return {
    caller: calleeName,
    arguments: args,
    span: node.span,
  };
}

function resolveUnaryExpression(node: UnaryExpression): Checker.Expression {
  if (node.argument.type === 'NumericLiteral') {
    return Number(`${node.operator}${node.argument.value}`);
  }
  return `${node.operator}${(node.argument as StringLiteral).value}`;
}

function resolveArrayExpression(node: ArrayExpression): Checker.Expression[] {
  const list: Checker.Expression[] = [];
  for (const arg of node.elements) {
    if (!arg) {
      continue;
    }
    if (checker.isNullLiteral(arg.expression)) {
      list.push(null);
    }
    if (arg.expression.type === 'UnaryExpression') {
      list.push(resolveUnaryExpression(arg.expression));
      continue;
    }
    if (checker.isPrimitive(arg.expression)) {
      list.push(arg.expression.value);
    }
    if (checker.isObjectExpression(arg.expression)) {
      list.push(resolveObjectExpression(arg.expression));
    }
    if (checker.isCallExpression(arg.expression)) {
      list.push(resolveCallExpression(arg.expression));
    }
  }
  return list;
}

function resolveObjectExpression(
  node: ObjectExpression,
  key?: string,
): Checker.Expression {
  const actions: Record<string, any> = {};
  for (const prop of node.properties) {
    if (!checker.isKeyValueProperty(prop)) {
      continue;
    }
    if (!checker.isIdentifier(prop.key)) {
      continue;
    }

    if (checker.isNullLiteral(prop.value)) {
      actions[prop.key.value] = null;
      continue;
    }

    if (prop.value.type === 'UnaryExpression') {
      actions[prop.key.value] = resolveUnaryExpression(prop.value);
      continue;
    }

    if (checker.isPrimitive(prop.value)) {
      actions[prop.key.value] = prop.value.value;
      continue;
    }

    if (checker.isKeyValueProperty<CallExpression>(prop, 'CallExpression')) {
      actions[prop.key.value] = resolveCallExpression(prop.value);
      continue;
    }
    if (checker.isArrayExpression(prop.value)) {
      actions[prop.key.value] = resolveArrayExpression(prop.value);
      continue;
    }
    if (checker.isObjectExpression(prop.value)) {
      actions[prop.key.value] = resolveObjectExpression(
        prop.value,
        prop.key.value,
      );
      continue;
    }
    if (prop.value.type === 'ArrowFunctionExpression') {
      if (sourceCode) {
        actions[prop.key.value] = sourceCode.slice(
          prop.value.span.start,
          prop.value.span.end,
        );

        if (key === 'actions') {
          if (checker.isCallExpression(prop.value.body)) {
            actions[prop.key.value] = resolveCallExpression(prop.value.body);
          }
        }
      }
    }
    // if (checker.isMemberExpression(prop.value)) {
    //   actions[prop.key.value] = resolveMemberExpression(prop.value, []);
    //   continue;
    // }
    // if (prop.value.type === 'TsAsExpression') {
    //   actions[prop.key.value] = resolveAsExpression(prop.value);
    //   continue;
    // }

    //Note: you can use this instead of resolving TsAsExpression and MemberExpression
    // it is needed now because the "as" will be forgotten about
    if (
      checker.isMemberExpression(prop.value) ||
      prop.value.type === 'TsAsExpression'
    ) {
      if (sourceCode) {
        actions[prop.key.value] = sourceCode.slice(
          prop.value.span.start,
          prop.value.span.end,
        );
      }
    }
  }
  return actions;
}

function resolveMemberExpression(
  node: MemberExpression,
  acc: readonly string[],
): readonly string[] {
  const collection = acc.slice(0);
  if (checker.isIdentifier(node.object)) {
    collection.push(node.object.value);
  }
  if (checker.isMemberExpression(node.object)) {
    collection.push(...resolveMemberExpression(node.object, acc));
  }
  if (checker.isIdentifier(node.property)) {
    collection.push(node.property.value);
  }
  return collection;
}
const exportTypes = [
  'ExportAllDeclaration',
  'ExportDeclaration',
  'ExportDefaultDeclaration',
  'ExportDefaultExpression',
  'ExportNamedDeclaration',
  'ImportDeclaration',
] as const;

function isExportItem<T extends ModuleItem>(
  item: unknown,
): item is
  | ExportDefaultExpression
  | ExportDefaultDeclaration
  | ExportNamedDeclaration
  | ExportAllDeclaration
  | ExportDeclaration {
  return exportTypes.some((x) => {
    return item && (item as ModuleItem).type === x;
  });
}

export async function getExports(code: string) {
  const ast = await parseCode(code);
  if (!ast) {
    return [];
  }

  return ast.body.filter(isExportItem);
}
