import type { diagnostic } from '@january/sdk/analyzer';

import type { ValidationDefinition } from './table';

export function mandatory(
  config: { message?: string } = {},
): ValidationDefinition {
  return {
    name: 'mandatory',
    config: {
      value: 'true',
      message: config.message,
    },
  };
}

export const required = mandatory;

export function unique(config: { message?: string } = {}) {
  return [
    mandatory(),
    {
      name: 'unique',
      config: {
        value: 'true',
        message: config.message,
      },
    },
  ];
}

function defineValidation(config: { name: string; message?: string }) {
  return {
    name: config.name,
    config,
  };
}
export namespace validation {
  export function fromConfig(
    type: string,
    ...args: unknown[]
  ): ValidationDefinition[] {
    const parts = type.split('.');
    let impl = parts.length ? (validation as any) : defineValidation;
    while (parts.length) {
      impl = (impl as any)[parts.shift() as any];
    }
    if (impl) {
      return impl(...args);
    }
    if (type.endsWith('.rule')) {
      const reports: diagnostic.Diagnostic[] = [];
      return reports as never;
    }
    throw new Error(`Unknown validation type: ${type}`);
  }
}
