export type TokenType =
  | 'AT'
  | 'IDENTIFIER'
  | 'STRING'
  | 'WHITESPACE'
  | 'COLON'
  | 'DOT'
  | 'OPEN_PAREN'
  | 'CLOSE_PAREN'
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'COMMA'
  | 'EOF';
export interface Token {
  type: TokenType;
  value: string;
  column?: number;
  line?: number;
}

export type AstValue = string | Call | PropertyAccess;

export interface Ast {
  namespace: string;
  value: AstValue;
}

export abstract class Expression {
  parent?: Expression;
  abstract type: unknown;
  abstract accept<T>(visitor: Visitor<T>): T;
  abstract toLiteral<T>(visitor: Visitor<T>): string;
}
export class Arg extends Expression {
  type = 'arg';
  constructor(
    public name: Identifier,
    public value: Expression,
  ) {
    super();
    this.name.parent = this;
    this.value.parent = this;
  }

  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitArg(this);
  }
  override toLiteral<T>(visitor: Visitor<T>): string {
    return `${this.name.toLiteral(visitor)}: ${this.value.toLiteral(visitor)}`;
  }
}

export class Call extends Expression {
  type = 'call';
  constructor(
    public name: Identifier,
    public args: Arg[] = [],
  ) {
    super();
    this.name.parent = this;
    this.args.forEach((arg) => (arg.parent = this));
  }
  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitCall(this);
  }
  override toLiteral<T>(visitor: Visitor<T>): string {
    return `${this.name.toLiteral(visitor)}(${this.args
      .map((arg) => arg.toLiteral(visitor))
      .join(', ')})`;
  }
}
export class PropertyAccess extends Expression {
  type = 'propertyAccess';
  constructor(
    public name: Expression,
    public expression: Expression,
  ) {
    super();
    this.name.parent = this;
    this.expression.parent = this;
  }
  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitPropertyAccess(this);
  }
  override toLiteral<T>(visitor: Visitor<T>): string {
    return `${this.name.toLiteral(visitor)}.${this.expression.toLiteral(
      visitor,
    )}`;
  }
}
export class Binary extends Expression {
  type = 'propertyAccess';
  constructor(
    public operator: Identifier,
    public left: Expression,
    public right: Expression,
  ) {
    super();
    this.operator.parent = this;
    this.left.parent = this;
    this.right.parent = this;
  }
  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitBinary(this);
  }
  override toLiteral<T>(visitor: Visitor<T>): string {
    return `${this.left.toLiteral(visitor)} ${this.operator.toLiteral(
      visitor,
    )} ${this.right.toLiteral(visitor)}`;
  }
}
export class Namespace extends Expression {
  type = 'namespace';
  constructor(
    public name: Identifier,
    public expression: Expression,
  ) {
    super();
    this.name.parent = this;
    this.expression.parent = this;
  }

  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitNamespace(this);
  }

  toLiteral<T>(visitor: Visitor<T>): string {
    return `@${this.name.value}:${this.expression.toLiteral(visitor)}`;
  }
}
export class Identifier extends Expression {
  type = 'identifier';
  constructor(public value: string) {
    super();
  }
  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitIdentifier(this);
  }
  override toLiteral<T>(visitor: Visitor<T>): string {
    return this.value;
  }
}

export class StringLiteral extends Expression {
  type = 'string';
  constructor(public value: string) {
    super();
  }
  override accept<T>(visitor: Visitor<T>): T {
    return visitor.visitStringLiteral(this);
  }
  override toLiteral<T>(visitor: Visitor<T>): string {
    return `'${this.value}'`;
  }
}

export const typeChecker = {
  isCall(expression: Expression): expression is Call {
    return expression.type === 'call';
  },
  isNamespace(expression: Expression): expression is Namespace {
    return expression.type === 'namespace';
  },
  isPropertyAccess(expression: Expression): expression is PropertyAccess {
    return expression.type === 'propertyAccess';
  },
  isIdentifier(expression: Expression): expression is Identifier {
    return expression.type === 'identifier';
  },
};

export abstract class Visitor<T> {
  abstract visitCall(node: Call): T;
  abstract visitPropertyAccess(node: PropertyAccess): T;
  abstract visitBinary(node: Binary): T;
  abstract visitNamespace(node: Namespace): T;
  abstract visitIdentifier(node: Identifier): T;
  abstract visitStringLiteral(node: StringLiteral): T;
  abstract visitArg(node: Arg): T;
  abstract visit(node: Expression): T;
}

export abstract class AsyncVisitor<T> {
  abstract visitCall(node: Call): Promise<T>;
  abstract visitPropertyAccess(node: PropertyAccess): Promise<T>;
  abstract visitBinary(node: Binary): Promise<T>;
  abstract visitNamespace(node: Namespace): Promise<T>;
  abstract visitIdentifier(node: Identifier): Promise<T>;
  abstract visitStringLiteral(node: StringLiteral): Promise<T>;
  abstract visitArg(node: Arg): Promise<T>;
  abstract visit(node: Expression): Promise<T>;
}
export abstract class StringVisitor extends Visitor<string> {
  override visitIdentifier(node: Identifier): string {
    return node.value;
  }
  override visitStringLiteral(node: StringLiteral): string {
    return `'${node.value}'`;
  }
  override visit(node: Expression): any {
    return node.accept(this);
  }
}

export abstract class StringAsyncVisitor extends AsyncVisitor<string> {
  override async visitIdentifier(node: Identifier): Promise<string> {
    return node.value;
  }
  override async visitStringLiteral(node: StringLiteral): Promise<string> {
    return `'${node.value}'`;
  }
  override visit(node: Expression): any {
    return node.accept(this);
  }
}
