import type {
  ConditionData,
  GenericQueryCondition,
  GroupQueryCondition,
  PeriodData,
  QueryColumn,
  QuerySelect,
  SemanticOperators,
  WithoutInverse,
} from '@january/compiler/transpilers';
import { QueryFactory } from '@january/compiler/transpilers';

import { coerceString } from './utils';

type FeatureKind =
  | 'column'
  | 'query'
  | 'group'
  | 'sort'
  | 'condition'
  | 'select'
  | 'limit';

export type Feature =
  | SelectFeature
  | LimitFeature
  // | QueryFeature
  | SortFeature
  | GroupFeature
  | ConditionFeature;

export interface SelectFeature {
  kind: 'select';
  implementation: QueryColumn;
}

export interface LimitFeature {
  kind: 'limit';
  implementation: unknown;
}

// export interface QueryFeature {
//   kind: 'query';
//   implementation: QuerySelect;
// }

export interface SortRule {
  id: string;
  input: string;
}

export interface SortFeature {
  kind: 'sort';
  implementation: SortRule;
}

export interface GroupFeature {
  kind: 'group';
  implementation: GroupQueryCondition<unknown>;
}
export interface ConditionFeature {
  kind: 'condition';
  implementation: GenericQueryCondition<unknown>;
}

// interface GroupFeature {
//   kind: 'group';
//   implementation: GroupQueryCondition<unknown>;
// }

export function where(
  column: string,
  operator: 'is',
  value: null | true | false | '',
): ConditionFeature;
export function where(
  column: string,
  operator: 'after' | 'before',
  value: WithoutInverse<PeriodData>,
): ConditionFeature;
export function where(
  column: string,
  operator: Exclude<
    SemanticOperators,
    'after' | 'before' | 'after_on' | 'before_on'
  >,
  value: string,
): ConditionFeature;
export function where(
  column: string,
  operator: SemanticOperators,
  value: string | number | WithoutInverse<PeriodData>,
): ConditionFeature;
export function where(
  column: string,
  operator: SemanticOperators,
  value:
    | string
    | number
    | WithoutInverse<PeriodData>
    | null
    | true
    | false
    | '',
): ConditionFeature {
  const columnName = coerceColumnName(column);
  let condition: GenericQueryCondition<unknown> | undefined;
  value = coerceString(value as never) as
    | string
    | number
    | WithoutInverse<PeriodData>;
  switch (operator) {
    case 'after':
      condition = QueryFactory.createPeriodRule(
        [columnName],
        'after',
        value as WithoutInverse<PeriodData>,
      );
      break;
    case 'before':
      condition = QueryFactory.createPeriodRule(
        [columnName],
        'before',
        value as WithoutInverse<PeriodData>,
      );
      break;
  }
  if (condition) {
    return {
      kind: 'condition',
      implementation: condition,
    };
  }

  if (typeof value !== 'string') {
    throw new Error(`Value ${value} is not supported`);
  }

  const data: ConditionData<any> = {
    type: 'string',
    input: value,
  } as const;

  switch (operator) {
    case 'equals':
      condition = QueryFactory.createEqualRule([columnName], data);
      break;
    case 'not_equals':
      condition = QueryFactory.createNotEqualRule([columnName], data);
      break;
    case 'more_than':
      condition = QueryFactory.createGreaterThanRule([columnName], data);
      break;
    case 'less_than':
      condition = QueryFactory.createLessThanRule([columnName], data);
      break;
    case 'more_than_or_equal':
      condition = QueryFactory.createGreaterThanEqualRule([columnName], data);
      break;
    case 'less_than_or_equal':
      condition = QueryFactory.createLessThanEqualRule([columnName], data);
      break;
    case 'is':
      condition = QueryFactory.createIsRule([columnName], data);
      break;
    case 'is_not_empty':
      condition = QueryFactory.createIsNotEmptyRule([columnName], data);
      break;
    case 'is_empty':
      condition = QueryFactory.createIsEmptyRule([columnName], data);
      break;
    case 'contains':
      condition = QueryFactory.createContainRule([columnName], data);
      break;
    case 'starts_with':
      condition = QueryFactory.createStartsWithRule([columnName], data);
      break;
    case 'ends_with':
      condition = QueryFactory.createEndsWithRule([columnName], data);
      break;
    default:
      throw new Error(`Operator ${operator} is not supported`);
  }

  return {
    kind: 'condition',
    implementation: condition,
  };
}

export function sort(
  name: string,
  input: string | 'desc' | 'DESC' | 'asc' | 'ASC',
): SortFeature {
  return {
    kind: 'sort',
    implementation: {
      id: coerceColumnName(name),
      input: input.startsWith('@fixed') ? input : `@fixed:${input}`,
    },
  };
}

export function isKind<T>(kind: FeatureKind): (feature: any) => feature is T;
export function isKind<T>(feature: any, kind: FeatureKind): feature is T;
export function isKind<T>(featureOrKind: any, kind?: FeatureKind) {
  if (!featureOrKind) {
    return false;
  }
  if (typeof featureOrKind === 'string') {
    return (feature: any): feature is T =>
      feature && feature.kind === featureOrKind;
  }
  return featureOrKind.kind === kind;
}

function assertKind<T>(
  feature: any,
  kind: FeatureKind,
  label?: string,
): asserts feature is T {
  if (!feature || feature.kind !== kind) {
    throw new Error(label ?? `Expected ${kind} feature`);
  }
}

export interface SqlQuery {
  whereBy?: QuerySelect;
  sortBy?: SortRule[];
  limit?: number;
}

export type HasQuery<A, T extends Feature[]> = T extends [infer F, ...infer R]
  ? F extends A
    ? true
    : HasQuery<A, R extends Feature[] ? R : never>
  : false;
export function query<T extends Feature[]>(
  //   ...features: HasQuery<QueryFeature, T> extends true ? T : never
  ...features: T
): SqlQuery {
  const sorts = features.filter(isKind<SortFeature>('sort'));
  const columns = features
    .filter((f) => f.kind === 'select')
    .map((f) => f.implementation as QueryColumn);

  const conditions = features.filter(isKind<ConditionFeature>('condition'));

  const groups = [
    ...features.filter((f) => f.kind === 'group'),
    and(...conditions), // wrap this in an "and" group to simulate implicit "and" condition
  ].map((f) => f.implementation as GroupQueryCondition<unknown>);

  const limit = features.find(isKind<LimitFeature>('limit'))?.implementation as
    | number
    | undefined;

  return {
    whereBy: QueryFactory.createSelectRule(groups, columns),
    sortBy: sorts.map((it) => it.implementation),
    limit: limit,
  };
}

query.sql = (sql: string) => {
  // transform sql to query
};

export function select(name: string): SelectFeature {
  return {
    kind: 'select',
    implementation: {
      name,
    },
  };
}
// select.count = (alias: string): SelectFeature => {
//   return {
//     kind: 'select',
//     implementation: {
//       alias: alias,
//       aggregator: 'count',
//       name: '*',
//     },
//   };
// };
// select.count = (...columns: string[]): SelectFeature => {
//   return {
//     kind: 'select',
//     implementation: {
//       count: columns,
//     },
//   };
// };

// export function query(...groups: GroupQueryCondition<unknown>[]): QueryFeature {
//   return {
//     kind: 'query',
//     implementation: QueryFactory.createSelectRule(groups, []),
//   };
// }

export function and(...features: ConditionFeature[]): GroupFeature {
  const rules = features.map((f) => f.implementation);

  return {
    kind: 'group',
    implementation: QueryFactory.createGroupRule('and', rules),
  };
}

export function or(...features: ConditionFeature[]): GroupFeature {
  const rules = features.map((f) => f.implementation);
  return {
    kind: 'group',
    implementation: QueryFactory.createGroupRule('or', rules),
  };
}

const coerceColumnName = (column: string) => {
  if (!column.startsWith('@tables')) {
    return `@tables:fields.${column}`;
  }
  return column;
};

export namespace date {
  export namespace after {
    export function startOfThisWeek() {
      return weeksAgo(0);
    }
    export function weekAgo() {
      return weeksAgo(1);
    }

    // TODO: add relativeTo parameter. if omitted, use 'now'
    export function weeksAgo(
      amount: number,
      relativeTo = 'now',
    ): WithoutInverse<PeriodData> {
      return {
        period: 'week',
        amount: -Math.abs(amount),
        relativeTo,
      };
    }

    function weekInYear(n: WeekNumber, relativeTo = 'now') {
      // filter records in the nth week of the year
      // now for the current year
      // static date for a specific year
    }

    function monthInYear(n: MonthNumber) {
      // same as weekInYear
    }

    export function startOfThisMonth() {
      return monthsAgo(0);
    }
    export function monthAgo() {
      return monthsAgo(1);
    }
    export function monthsAgo(amount: number): WithoutInverse<PeriodData> {
      return {
        period: 'month',
        amount: -Math.abs(amount),
        relativeTo: 'now',
      };
    }

    export function startOfThisYear() {
      return yearsAgo(0);
    }
    export function yearAgo() {
      return yearsAgo(1);
    }
    export function yearsAgo(amount: number): WithoutInverse<PeriodData> {
      return {
        period: 'year',
        amount: -Math.abs(amount),
        relativeTo: 'now',
      };
    }
  }
}

export function limit(upTo: number): LimitFeature {
  return {
    kind: 'limit',
    implementation: upTo,
  };
}
type WeekNumber =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 51;

type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
