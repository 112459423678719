import type { diagnostic } from '@january/sdk/analyzer';
import type { MessagePayload, WebhookMessageCreateOptions } from 'discord.js';
import { cstVisitor, parse, show } from 'sql-parser-cst';
import { Project, StructureKind, SyntaxKind } from 'ts-morph';

import type { RuleFn } from '../evaluate';
import { input } from './functional';
import type { SqlQuery } from './query';
import { query, where } from './query';
import type { InputDefinition } from './table';
import { type UseField, type UseTable } from './table';
import { actionSymbol, isActionDefinition } from './utils';
import type {
  ActionDefinition,
  TriggerDefinition,
  WorkflowDefinition,
} from './workflow';

function defineAction<T>(config: {
  type: string;
  outputName?: string;
  config: Record<string, unknown>;
}): ActionDefinition {
  return {
    [actionSymbol]: true,
    [Symbol.hasInstance]: (instance: any) => {
      return instance[actionSymbol] === true;
    },
    type: config.type,
    config: config.config,
    outputName: config.outputName ?? '',
  };
}

export function action<T>(
  transferable: string | ((trigger: T) => void),
  config?: {
    inline?: boolean;
    structures?: unknown[];
  },
): ActionDefinition {
  let body = 'return true';
  const guard = transferable.toString();
  const project = new Project({
    useInMemoryFileSystem: true,
  });
  const sourceFile = project.createSourceFile(
    'guard.ts',
    `const guard = ${guard}`,
  );

  const triggerIdentifierText = 'trigger';

  let guardFunction = sourceFile
    .getVariableDeclarationOrThrow('guard')
    .getInitializerIfKind(SyntaxKind.ArrowFunction);

  if (!guardFunction) {
    guardFunction = sourceFile
      .set({
        statements: [`const guard = ()=>${guard}`],
      })
      .getVariableDeclarationOrThrow('guard')
      .getInitializerIfKindOrThrow(SyntaxKind.ArrowFunction);
  }

  const inputs: string[] = [];
  const triggerUses = guardFunction
    .getDescendantsOfKind(SyntaxKind.Identifier)
    .filter((identifier) => identifier.getText() === triggerIdentifierText);

  for (const triggerUse of triggerUses) {
    const parent = triggerUse.getParentWhileKind(
      SyntaxKind.PropertyAccessExpression,
    );
    if (!parent) {
      continue;
    }
    const triggerUsageText = parent.getText();
    const [namespace, ...rest] = triggerUsageText.split('.');

    inputs.push(`@${namespace}:${rest.join('.')}`);
    const key = rest.at(-1) as string;
    parent.replaceWithText(key);
  }

  body = guardFunction.getBodyText();

  return defineAction({
    type: 'custom-code',
    outputName: '',
    config: {
      structures: [],
      ...(config ?? {}),
      inline: config?.inline ?? true,
      code: body,
      inputs: inputs.reduce(
        (acc, it) => ({
          ...acc,
          [it.split('.').at(-1) as string]: input(it),
        }),
        {},
      ),
    },
  });
}

// the intended use of this action is to be used
// as custom return type of an action or within a branch action
// think "return early if post is published"
function actionOutput(config: {
  outputName: string;
  path: string;
}): ActionDefinition {
  return defineAction({
    type: 'raw',
    config: config,
    outputName: config.outputName,
  });
}

action.output = actionOutput;

// FIXME: I think we need to make it array of actions instead of record of actions
// because we don't need to have name all the time. also name can be used as indicator
// to making the action as function (inline: true)
export namespace action {
  export function trigger(
    transferable: ActionDefinition | unknown,
  ): ActionDefinition {
    if (isActionDefinition(transferable)) {
      return transferable as ActionDefinition;
    }
    // treat as custom code
    return action(transferable as string);
  }
  export namespace core {
    export function raw<T>(config: {
      code: string;
      inline: boolean;
    }): ActionDefinition {
      return action(config.code, {
        inline: config.inline,
      });
    }
    export function branch(config: {
      paths: Record<string, string>;
    }): ActionDefinition {
      const fallback = {
        id: 'fallback',
        name: 'fallback',
        rule: '',
      };
      const paths = Object.entries(config.paths).map(([name, rule]) => {
        return {
          id: crypto.randomUUID(),
          name,
          rule,
        };
      });
      return defineAction({
        type: 'branch',
        config: {
          ...config,
          paths: [fallback, ...paths],
        },
        outputName: '',
      });
    }
    export const output = action.output;
  }

  export namespace unstable_discord {
    // this is new version of actions in which we can use the trigger object instead of input dsl
    // input dsl suffers from manipulation.
    export function send(config: {
      webhook: string;
      // message: string | MessagePayload | WebhookMessageCreateOptions;
      message: any
    }) {
      return action(
        `
           const webhook = new WebhookClient('${config.webhook}');
           await webhook.send(${JSON.stringify(config.message)});
      `,
        {
          structures: [
            {
              kind: StructureKind.ImportDeclaration,
              moduleSpecifier: 'discord.js',
              namedImports: ['WebhookClient'],
            },
          ],
        },
      );
    }
  }
  export namespace database {
    export const list = listRecord;
    export const single = singleRecord;
    export const search = searchRecords;
    export const set = setFields;
    export const remove = deleteRecord;
    export const exists = recordExists;
    export const upsert = upsertRecord;
    export const increment = incrementField;
    export const decrement = decrementField;
    export function insert<Workflow>(config: {
      outputName?: string;
      table: UseTable<Workflow>;
      columns: UseField<Workflow>[];
    }): ActionDefinition {
      return defineAction({
        type: 'insert-record',
        outputName: config.outputName,
        config: {
          tableId: config.table,
          columns: config.columns,
        },
      });
    }
    export function sql<T>(config: {
      outputName?: string;
      query: string;
    }): ActionDefinition {
      const cst = parse(config.query, {
        dialect: 'sqlite',
        includeSpaces: true,
        includeNewlines: true,
        includeComments: false,
        includeRange: true,
        paramTypes: [],
      });

      const parameters: InputDefinition[] = [];

      // convert all keywords to uppercase
      const toUpper = cstVisitor({
        string_literal: (str) => {
          if (str.text.includes('@trigger:')) {
            parameters.push(input(str.text.replace("'", '').replace("'", '')));
            str.text = `\${${
              str.text
                .replace("'", '')
                .replace("'", '')
                .split('.')
                .at(-1) as string
            }}`;
          }
        },
        keyword: (kw) => {
          kw.text = kw.text.toUpperCase();
        },
      });
      toUpper(cst);
      return defineAction({
        type: 'raw-sql-query',
        outputName: config.outputName,
        config: {
          query: show(cst),
          parameters,
        },
      });
    }

    insert.rule = (({ node }, service) => {
      const reports: diagnostic.Diagnostic[] = [];
      return reports;
    }) satisfies RuleFn;
  }

  function incrementField<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    field: UseField<Workflow>;
    query?: SqlQuery;
  }): ActionDefinition {
    return defineAction({
      type: 'increment-field',
      outputName: config.outputName,
      config: {
        tableId: config.table,
        field: config.field,
        query: config.query,
      },
    });
  }

  function decrementField<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    field: UseField<Workflow>;
    query?: SqlQuery;
  }): ActionDefinition {
    return defineAction({
      type: 'decrement-field',
      outputName: config.outputName,
      config: {
        tableId: config.table,
        field: config.field,
        query: config.query,
      },
    });
  }

  export type ListRecordConfig<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  > = {
    outputName?: string;
    pagination?: 'limit_offset' | 'deferred_joins' | 'cursor' | 'none';
    table: UseTable<Workflow>;
    query?: SqlQuery;
    limit?: number;
  };

  function listRecord<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: ListRecordConfig<Workflow>): ActionDefinition {
    return defineAction({
      type: 'list-records',
      outputName: config.outputName,
      config: {
        ...config,
        limit: config.limit ?? config.query?.limit,
        tableId: config.table,
        query: config.query ?? query(),
      },
    });
  }

  function searchRecords<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    fields: UseField<Workflow>[];
    queryTerm?: string;
    pagination?: 'limit_offset' | 'deferred_joins' | 'cursor' | 'none';
    limit?: number;
  }): ActionDefinition {
    return action.database.list({
      outputName: config.outputName,
      ...config,
      limit: config.limit ?? 50,
      pagination: config.pagination ?? 'deferred_joins',
      query: query(
        ...config.fields.map((it) =>
          where('name', 'contains', config.queryTerm || '@trigger:query.query'),
        ),
      ),
    });
  }
  searchRecords.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
  function singleRecord<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    query: SqlQuery;
  }): ActionDefinition {
    return defineAction({
      type: 'list-records',
      outputName: config.outputName,
      config: {
        limit: 1,
        pagination: 'none',
        tableId: config.table,
        query: config.query,
      },
    });
  }
  singleRecord.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;

  function upsertRecord<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    conflictFields?: UseField<Workflow>[];
    columns: UseField<Workflow>[];
  }): ActionDefinition {
    return defineAction({
      type: 'upsert-record',
      outputName: config.outputName,
      config: {
        tableId: config.table,
        columns: config.columns,
        conflictFields: config.conflictFields ?? [],
      },
    });
  }
  function setFields<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    columns: UseField<Workflow>[];
    query: SqlQuery;
  }): ActionDefinition {
    return defineAction({
      type: 'set-fields',
      outputName: config.outputName,
      config: {
        tableId: config.table,
        columns: config.columns,
        query: config.query,
      },
    });
  }
  setFields.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
  function deleteRecord<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    query: SqlQuery;
  }): ActionDefinition {
    return defineAction({
      type: 'delete-record',
      outputName: config.outputName,
      config: {
        tableId: config.table,
        query: config.query,
      },
    });
  }
  deleteRecord.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
  function recordExists<
    Workflow extends WorkflowDefinition<
      TriggerDefinition<unknown, unknown>,
      any
    >[],
  >(config: {
    outputName?: string;
    table: UseTable<Workflow>;
    query: SqlQuery;
  }): ActionDefinition {
    return defineAction({
      type: 'check-record-existance',
      outputName: config.outputName,
      config: {
        tableId: config.table,
        query: config.query,
      },
    });
  }
  recordExists.rule = (() => {
    const reports: diagnostic.Diagnostic[] = [];
    return reports;
  }) satisfies RuleFn;
}

/**
 * Example usage
 *
 * omit outputName from config and use it as feature
 * features: [utils.withOutput('')]
 *
 */
export namespace utils {
  export function withOutput(value: string) {
    //
  }
}

export namespace action {
  export function fromConfig(
    type: string,
    ...args: unknown[]
  ): ActionDefinition {
    const parts = type.split('.');

    let impl = parts.length ? (action as any) : defineAction;
    while (parts.length) {
      impl = (impl as any)[parts.shift() as any];
    }
    if (impl) {
      return impl(...args);
    }
    if (type.endsWith('.rule')) {
      const reports: diagnostic.Diagnostic[] = [];
      return reports as never;
    }
    throw new Error(`Unknown action type: ${type}`);
  }
  export namespace googleCloudStorage {
    export function uploadSingle(config: {
      outputName?: string;
      maxFileSize?: number;
      types?: string[];
    }) {
      return defineAction({
        type: 'upload-file',
        outputName: config.outputName,
        config: {
          ...config,
          // maxFileSize: config.maxFileSize ?? 50 * 1024 * 1024,
        },
      });
    }

    uploadSingle.rule = (() => {
      const reports: diagnostic.Diagnostic[] = [];
      return reports;
    }) satisfies RuleFn;
  }
}

export namespace action {
  export namespace resend {
    export function sendEmail(config: {
      outputName?: string;
      to: string;
      subject: string;
      from: string;
      html: string;
    }) {
      return defineAction({
        type: 'resend-send-email',
        outputName: config.outputName,
        config: {
          to: input(config.to),
          subject: input(config.subject),
          from: input(config.from),
          html: input(config.html),
        },
      });
    }

    export function createContact(config: {
      outputName?: string;
      email: string;
      firstName: string;
      audienceId: string;
    }) {
      return defineAction({
        type: 'resend-create-contact',
        outputName: config.outputName,
        config: {
          email: input(config.email),
          firstName: input(config.firstName),
          audienceId: input(config.audienceId),
        },
      });
    }

    sendEmail.rule = (() => {
      const reports: diagnostic.Diagnostic[] = [];
      return reports;
    }) satisfies RuleFn;

    createContact.rule = (() => {
      const reports: diagnostic.Diagnostic[] = [];
      return reports;
    }) satisfies RuleFn;
  }

  export function openai() {
    //
  }
  export namespace openai {
    //
  }
}
