import { Checker, type diagnostic } from '@january/sdk/analyzer';

import type { RuleFn } from '../evaluate';
import type { TableDefinition } from './table';

export interface PolicyDefinition {
  name: string;
  rule: string;
}

export interface FeatureDefinition<W> {
  name: string;
  workflows: W[];
  tables: Record<string, TableDefinition>;
  policies?: Record<string, string>;
}

export interface ComponentDefinition {
  name: string;
  dsl: string;
}
export type Policy = (name: string) => string;
export function feature<W>(
  name: string,
  config: {
    experimental?: unknown[];
    workflows: W[];
    tables: Record<string, TableDefinition>;
    components?: ComponentDefinition[];
    policies?: Record<string, Policy>;
  },
): FeatureDefinition<W> {
  return {
    name,
    tables: config.tables,
    workflows: config.workflows,
    policies: Object.entries(config.policies || {}).reduce(
      (acc, [key, value]) => {
        const result = value(key);
        if (result) {
          acc[key] = result;
        }
        return acc;
      },
      {} as Record<string, string>,
    ),
  };
}

feature.new = <W>(
  name: string,
  config: {
    experimental?: unknown[];
    workflows: Record<string, (name: string) => W>;
    tables: Record<string, TableDefinition>;
    components?: ComponentDefinition[];
    policies?: Record<string, Policy>;
  },
): FeatureDefinition<W> => {
  return feature(name, {
    ...config,
    workflows: Object.entries(config.workflows).map(([key, value]) =>
      value(key),
    ),
  });
};

feature.rule = (({ node }, service) => {
  const reports: diagnostic.Diagnostic[] = [];
  // TODO: check for workflow name duplication

  if (!Checker.isCallExpression(node)) {
    return reports;
  }
  const [featureName, config] = node.arguments;
  const errors = service.validateName(featureName, 'Feature');
  for (const error of errors) {
    reports.push({
      message: error,
      span: node.span,
      node: node as any,
      severity: 'error',
    });
  }

  if (errors.length) {
    return reports;
  }

  if (!service.inUniqueFeature(featureName as string)) {
    reports.push({
      message: `Feature "${featureName}" is already defined`,
      span: node.span,
      node: node as any,
      severity: 'error',
    });
  }

  if (Checker.isObjectExpression(config)) {
    // will be caught by syntax validation
    if (!('tables' in config)) {
      reports.push({
        message: 'Feature must have tables',
        span: node.span,
        node: node as any,
        severity: 'error',
      });
    }
  }
  return reports;
}) satisfies RuleFn;
