import type { Checker, diagnostic } from '@january/sdk/analyzer';

import type { RuleFn } from '../evaluate';
import type { FeatureDefinition } from './feature';
import type {
  ActionDefinition,
  InferTriggerArg,
  TriggerDefinition,
  WorkflowDefinition,
} from './workflow';

export interface ProjectDefinition<W> {
  name: string;
  features: FeatureDefinition<W>[];
  extensions: Record<string, ExtensionDefinition>;
  imports: Checker.ProjectImport[];
}

export type EmptyProject<
  T extends TriggerDefinition<unknown, unknown> = TriggerDefinition<
    unknown,
    unknown
  >,
> = ProjectDefinition<
  WorkflowDefinition<
    T,
    Record<
      string,
      ActionDefinition | ((trigger: InferTriggerArg<T>) => ActionDefinition)
    >
  >
>;

export type ExtensionDefinition = Record<string, unknown>;
export function current_Project<W>(
  name: string,
  config: {
    extensions: Record<string, ExtensionDefinition>;
    modules: FeatureDefinition<W>[];
    imports?: Checker.ProjectImport[];
  },
): ProjectDefinition<W> {
  return {
    name,
    features: config.modules,
    extensions: config.extensions,
    imports: config.imports ?? [],
  };
}

export function project<W>(...features: FeatureDefinition<W>[]) {
  return features;
}

project.rule = (({ node }, service) => {
  const reports: diagnostic.Diagnostic[] = [];

  return reports;
}) satisfies RuleFn;
