import { toLiteralObject } from '../utils';
import { parseDsl } from './input-parser';
import type {
  Arg,
  Binary,
  Call,
  Expression,
  Identifier,
  Namespace,
  PropertyAccess,
  StringLiteral} from './token';
import {
  Visitor,
} from './token';

export class SqliteVisitor extends Visitor<string> {
  override visitArg(node: Arg): string {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): string {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): string {
    const where = node.args.map((arg) => arg.accept(this)).join(', ');
    return `${node.name.accept(this)} WHERE id = ${where}`;
  }
  override visitNamespace(node: Namespace): string {
    if (node.name.value === 'tables') {
      return `SELECT ${node.expression.accept(this)}`;
    }
    return `'${node.toLiteral(this)}'`;
  }
  override visitPropertyAccess(node: PropertyAccess): string {
    return `${node.expression.accept(this)} FROM ${node.name.accept(this)}`;
  }

  override visitIdentifier(node: Identifier): string {
    return node.value;
  }

  override visitStringLiteral(node: StringLiteral): string {
    return `'${node.value}'`;
  }

  override visit(node: Expression): string {
    return node.accept(this);
  }
}

export function toSqlite(input: string) {
  const visitor = new SqliteVisitor();
  return visitor.visit(parseDsl(input));
}

class TypeormVisitor extends Visitor<string> {
  override visitArg(node: Arg): string {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): string {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): string {
    const where = node.args.reduce(
      (acc, current) => {
        return {
          ...acc,
          // static to id till we support multiple args
          id: current.accept(this),
        };
      },
      {} as Record<string, any>,
    );
    const tableName = node.name.accept(this);
    return `.from('${tableName}', '${tableName}').andWhere('id = :id', ${toLiteralObject(
      where,
      (value) => value,
    )})`;
  }
  override visitPropertyAccess(node: PropertyAccess): string {
    return `.select('${node.expression.accept(this)}')${node.name.accept(
      this,
    )}`;
  }
  override visitNamespace(node: Namespace): string {
    if (node.name.value === 'tables') {
      return `qb${node.expression.accept(this)}`;
    }
    return `'${node.toLiteral(this)}'`;
  }
  override visitIdentifier(node: Identifier): string {
    return node.value;
  }
  override visitStringLiteral(node: StringLiteral): string {
    return `'${node.value}'`;
  }
  override visit(node: Expression): string {
    return node.accept(this);
  }
}

export function toTypeorm(input: string) {
  const visitor = new TypeormVisitor();
  return visitor.visit(parseDsl(input));
}
type SimpleReturn = Record<string, any> | string;
class SimpleVisitor extends Visitor<SimpleReturn> {
  override visitArg(node: Arg): SimpleReturn {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): SimpleReturn {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): SimpleReturn {
    return node.toLiteral(this);
  }
  override visitPropertyAccess(node: PropertyAccess): SimpleReturn {
    return node.toLiteral(this);
  }
  override visitNamespace(node: Namespace): SimpleReturn {
    return {
      namespace: node.name.value,
      value: node.expression.accept(this),
    };
  }

  override visitIdentifier(node: Identifier): SimpleReturn {
    return node.value;
  }

  override visitStringLiteral(node: StringLiteral): SimpleReturn {
    return `'${node.value}'`;
  }

  override visit(node: Expression): SimpleReturn {
    return node.accept(this);
  }
}

export function toSimple(input: string) {
  const visitor = new SimpleVisitor();
  return visitor.visit(parseDsl(input)) as { namespace: string; value: string };
}
