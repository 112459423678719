import type { diagnostic } from '@january/sdk/analyzer';
import { Checker, parseDeclarative } from '@january/sdk/analyzer';

import { logMe } from '@faslh/utils';

import { action } from './lib/database';
import { feature } from './lib/feature';
import { input } from './lib/functional';
import { output } from './lib/output';
import type { EmptyProject } from './lib/project';
import { project } from './lib/project';
import { and, limit, or, query, select, sort, where } from './lib/query';
import { field, index, table, useField, useTable } from './lib/table';
import { mandatory, required, unique, validation } from './lib/validation';
import type { TriggerDefinition, WorkflowDefinition } from './lib/workflow';
import { policy, sequenceFor, trigger, workflow } from './lib/workflow';

const callers: Record<string, (...args: any[]) => void> = {
  project,
  table,
  feature,
  workflow,
  output,
  action: action.fromConfig,
  field: field.fromConfig,
  policy: policy.fromConfig,
  trigger: trigger.fromConfig,
  validation: validation.fromConfig,
  mandatory,
  required,
  unique,
  useTable,
  useField,
  query,
  select,
  sort,
  where,
  limit,
  and,
  or,
  sequenceFor,
  input,
  index,
  withTrigger: (...args: any[]) => {
    // replace action.trigger with it just in case we don't replicate the same logic
    logMe({ withTrigger: args });
  },
};

function call(
  node: unknown,
  parent: Checker.Expression | null = null,
  metadata?: any,
): unknown {
  if (Checker.isCallExpression(node)) {
    const [implFn, ...type] = node.caller.split('.');
    const callerImpl = callers[implFn];
    if (!callerImpl) {
      throw new Error(`Unknown caller ${node.caller}`);
    }
    const args = node.arguments.map((it) => call(it, node));
    if (type.length) {
      args.unshift(type.join('.'));
    }
    return callerImpl(...args);
  }

  if (Checker.isArrayExpression(node)) {
    return node.map((it) => call(it, node));
  }

  if (Checker.isObjectExpression(node)) {
    const obj: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(node)) {
      if (metadata === 'actions') {
        obj[key] = call(
          {
            caller: 'action.trigger',
            arguments: [value],
          },
          node,
          key,
        );
      } else {
        obj[key] = call(value, node, key);
      }
    }
    return obj;
  }
  return node;
}

function diagnose(
  service: RuleService,
  ast: { node: unknown; parent: unknown },
): unknown[] {
  const reports: unknown[] = [];
  if (Checker.isCallExpression(ast.node)) {
    const [implFn, ...type] = ast.node.caller.split('.');
    const callerImpl = callers[implFn];
    if (!callerImpl) {
      throw new Error(`Unknown caller ${ast.node.caller}`);
    }
    const args: any[] = [ast, service];
    reports.push(
      ...ast.node.arguments.map((it) =>
        diagnose(service, {
          parent: ast,
          node: it,
        }),
      ),
    );
    if (type.length) {
      args.unshift([...type, 'rule'].join('.'));
      reports.push(...(callerImpl as any)(...args));
    } else {
      if ('rule' in callerImpl) {
        reports.push(...(callerImpl.rule as any)(...args));
      }
    }
    return reports;
  }

  if (Checker.isArrayExpression(ast.node)) {
    return ast.node.map((it) =>
      diagnose(service, {
        parent: ast,
        node: it,
      }),
    );
  }

  if (Checker.isObjectExpression(ast.node)) {
    for (const [key, value] of Object.entries(ast.node)) {
      reports.push(
        ...diagnose(service, {
          parent: ast,
          node: value,
        }),
      );
    }
    return reports;
  }
  return reports;
}
export interface RuleService {
  isUniqueWorkflow: (name: string) => boolean;
  hasTable: (name: string) => boolean;
  hasField: (name: string) => boolean;
  validateName: (name: unknown, context: string) => string[];
  inUniqueFeature: (name: string) => boolean;
  getFeature: (descendantNode: any) => EmptyProject['features'][0] | undefined;
  getWorkflow: (
    descendantNode: any,
  ) => WorkflowDefinition<TriggerDefinition<unknown, unknown>> | undefined;
  duplicateWorkflowTriggerConfig: (
    relatedWorkflow: unknown,
    compare: (
      workflowConfig: WorkflowDefinition<TriggerDefinition<unknown, unknown>>,
      feature: EmptyProject['features'][0],
    ) => boolean,
  ) => boolean;
}
export type RuleFn = (
  ast: {
    parent: unknown;
    node: unknown;
  },
  service: RuleService,
) => diagnostic.Diagnostic[];

export async function evaluate(code: string) {
  const ast = await parseDeclarative(code);
  if (!ast) {
    throw new Error('Failed to parse the code');
  }
  const features = call(ast.project) as EmptyProject['features'];
  const service: RuleService = {
    getFeature(descendantNode: any) {
      let parent = descendantNode.parent;
      while (parent) {
        if (
          Checker.isCallExpression(parent.node) &&
          parent.node.caller === 'feature'
        ) {
          return features.find(
            (feature) => feature.name === parent.node.arguments[0],
          );
        }
        parent = parent.parent;
      }
      return void 0;
    },
    getWorkflow(descendantNode: any) {
      let parent = descendantNode.parent;
      while (parent) {
        if (
          Checker.isCallExpression(parent.node) &&
          parent.node.caller === 'workflow'
        ) {
          const feature = this.getFeature(parent);
          const [workflowName] = parent.node.arguments;
          return feature?.workflows.find(
            (workflow) => workflow.name === workflowName,
          );
        }
        parent = parent.parent;
      }
      return void 0;
    },
    duplicateWorkflowTriggerConfig: (relatedWorkflow, check) => {
      for (const feature of features) {
        for (const workflow of feature.workflows) {
          if (workflow === relatedWorkflow) {
            continue;
          }
          if (check(workflow, feature)) {
            return true;
          }
        }
      }
      return false;
    },
    hasField: (name) =>
      features.some((feature) =>
        Object.keys(feature.tables ?? {}).some((table) =>
          Object.keys(feature.tables[table].fields).includes(name),
        ),
      ),
    hasTable: (name) =>
      features.some((feature) => {
        return (feature.tables ?? {})[name];
      }),
    inUniqueFeature: (name) => {
      let count = 0;
      for (const feature of features) {
        if (feature.name === name) {
          count++;
        }
      }
      return count === 1;
    },
    isUniqueWorkflow: (name) => {
      for (const feature of features) {
        let count = 0;
        for (const workflow of feature.workflows) {
          if (workflow.name === name) {
            count++;
          }
        }
        if (count > 1) {
          return false;
        }
      }
      return true;
    },
    validateName(name, context) {
      const errors: string[] = [];
      // maybe use ajv here?
      if (typeof name !== 'string') {
        errors.push(`${context} must be a string`);
      } else if (name.trim().length === 0) {
        errors.push(`${context} must not be empty`);
      }
      return errors;
    },
  };
  const reports = diagnose(service, {
    parent: null,
    node: ast,
  }).flat(Infinity) as diagnostic.Diagnostic[];
  return {
    reports,
    definition: {
      features,
      imports: ast.imports,
      extensions: {},
      name: '',
    } as EmptyProject,
  };
}

export async function compare(a: string, b: string) {
  const removeSpans = (node: Checker.CallExpression | null): string => {
    if (!node) return '';
    return JSON.stringify(node, (key, value) => {
      if (key === 'span') {
        return undefined;
      }
      return value;
    });
  };
  // const astA = await parseDeclarative(code);
  // const astB = call(b) as EmptyProject['features'];

  return (
    removeSpans((await parseDeclarative(a))?.project ?? null) ===
    removeSpans((await parseDeclarative(a))?.project ?? null)
  );
}
