import type { ComponentDefinition } from './feature';
import type { ExtensionDefinition } from './project';
import type { InputDefinition } from './table';
import type { TagDefinition } from './workflow';

export function tag(name: string): TagDefinition {
  return {
    name,
  };
}

export function component(name: string, dsl: string): ComponentDefinition {
  return {
    name,
    dsl,
  };
}

export function extension(
  name: string,
  config: Record<string, unknown>,
): ExtensionDefinition {
  return {
    name,
    details: config,
  };
}

export function input(
  value: string | number | boolean | null,
): InputDefinition {
  if (
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null
  ) {
    return input.primitive(value);
  }

  if (value.startsWith('trigger')) {
    return input.trigger(value.replace('trigger.', ''));
  }
  if (!value.startsWith('@')) {
    return input.primitive(value);
  }
  return {
    input: value,
  };
}

export namespace input {
  export function primitive(
    value: string | number | boolean | null,
  ): InputDefinition {
    return {
      input: `@fixed:${typeof value === 'boolean' || typeof value === 'number' || value === null ? value : `'${value}'`}`,
    };
  }

  export function trigger(path: string): InputDefinition {
    return {
      input: `@trigger:${path}`,
    };
  }

  export function field(name: string): InputDefinition {
    return {
      input: `@tables:fields.${name}`,
    };
  }
}
