import type {
  BetweenData,
  BetweenQueryCondition,
  ConditionData,
  ContainData,
  ContainQueryCondition,
  DefaultQueryCondition,
  GenericQueryCondition,
  GroupQueryCondition,
  OneOfData,
  OneOfQueryCondition,
  PeriodData,
  PeriodQueryCondition,
  QueryColumn,
  QuerySelect,
} from './interfaces';

export type WithoutInverse<T extends { inverse?: boolean }> = Omit<
  T,
  'inverse'
>;

type EmptyConditionData = { inverse: boolean };

export namespace QueryFactory {
  export function createEmptyGroupRule(): GroupQueryCondition<any> {
    return {
      data: [],
      operator: 'group',
      input: ['and'],
    };
  }

  export function createGroupRule(
    combinator: 'and' | 'or',
    data: GenericQueryCondition<unknown>[],
  ): GroupQueryCondition<unknown> {
    return {
      input: [combinator],
      operator: 'group',
      data,
    };
  }

  export function createSelectRule(
    groups: GroupQueryCondition<any>[],
    columns?: QueryColumn[],
  ): QuerySelect {
    return {
      operator: 'querySelect',
      input: columns ?? [],
      data: groups,
    };
  }

  export function createIsEmptyRule(
    input: string[],
    data: Pick<ConditionData, 'type'>,
  ): DefaultQueryCondition<WithoutInverse<EmptyConditionData>> {
    return {
      operator: 'is_empty',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createIsNotEmptyRule(
    input: string[],
    data: Pick<ConditionData, 'type'>,
  ): DefaultQueryCondition<WithoutInverse<EmptyConditionData>> {
    return {
      operator: 'is_empty',
      input,
      data: {
        ...data,
        inverse: true,
      },
    };
  }

  export function createEqualRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'equals',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createGreaterThanRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'more_than',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createGreaterThanEqualRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'more_than_or_equal',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createLessThanRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'less_than',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createLessThanEqualRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'less_than_or_equal',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createIsRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'is',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createIsNotRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'is',
      input,
      data: {
        ...data,
        inverse: true,
      },
    };
  }

  export function createNotEqualRule(
    input: string[],
    data: Omit<ConditionData, 'inverse'>,
  ): DefaultQueryCondition<ConditionData> {
    return {
      operator: 'equals',
      input,
      data: {
        ...data,
        inverse: true,
      },
    };
  }

  export function createOneOfRule(
    input: string[],
    data: Omit<OneOfData, 'inverse'>,
  ): OneOfQueryCondition<OneOfData> {
    return {
      operator: 'one_of',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createNotOneOfRule(
    input: string[],
    data: Omit<OneOfData, 'inverse'>,
  ): OneOfQueryCondition<OneOfData> {
    return {
      operator: 'one_of',
      input,
      data: {
        ...data,
        inverse: true,
      },
    };
  }

  export function createBetweenRule(
    input: string[],
    data: Omit<BetweenData<WithoutInverse<ConditionData>>, 'inverse'>,
  ): BetweenQueryCondition<BetweenData<WithoutInverse<ConditionData>>> {
    return {
      operator: 'between',
      input,
      data: {
        ...data,
        inverse: false,
      },
    };
  }

  export function createNotBetweenRule(
    input: string[],
    data: WithoutInverse<BetweenData<ConditionData>>,
  ): BetweenQueryCondition<BetweenData<ConditionData>> {
    return {
      operator: 'between',
      input,
      data: {
        ...data,
        inverse: true,
      },
    };
  }

  export function createContainRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'contains',
      input,
      data: {
        ...data,
        inverse: false,
        prefix: '%',
        postfix: '%',
      },
    };
  }

  export function createMatchRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'contains',
      input,
      data: {
        ...data,
        inverse: false,
        prefix: '',
        postfix: '',
      },
    };
  }

  export function createNotMatchRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'contains',
      input,
      data: {
        ...data,
        inverse: true,
        prefix: '',
        postfix: '',
      },
    };
  }

  export function createNotContainRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'contains',
      input,
      data: {
        ...data,
        inverse: true,
        prefix: '%',
        postfix: '%',
      },
    };
  }

  export function createStartsWithRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'starts_with',
      input,
      data: {
        ...data,
        inverse: false,
        prefix: '',
        postfix: '%',
      },
    };
  }

  export function createNotStartsWithRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'starts_with',
      input,
      data: {
        ...data,
        inverse: true,
        prefix: '',
        postfix: '%',
      },
    };
  }

  export function createEndsWithRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'ends_with',
      input,
      data: {
        ...data,
        inverse: false,
        prefix: '%',
        postfix: '',
      },
    };
  }

  export function createNotEndsWithRule(
    input: string[],
    data: Omit<ConditionData<string>, 'inverse'>,
  ): ContainQueryCondition<ContainData> {
    return {
      operator: 'ends_with',
      input,
      data: {
        ...data,
        inverse: true,
        prefix: '%',
        postfix: '',
      },
    };
  }

  export function createPeriodRule(
    input: string[],
    operator: 'after' | 'before' | 'after_on' | 'before_on',
    data: Omit<PeriodData, 'inverse'>,
  ): PeriodQueryCondition<PeriodData> {
    return {
      operator,
      input,
      data: {
        ...data,
        input: '',
        inverse: false,
      },
    };
  }

  export function createNotPeriodRule(
    input: string[],
    operator: 'after' | 'before' | 'after_on' | 'before_on',
    data: Omit<PeriodData, 'inverse'>,
  ): PeriodQueryCondition<PeriodData> {
    return {
      operator,
      input,
      data: {
        ...data,
        input: '',
        inverse: true,
      },
    };
  }
}
