export type SqlOperator =
  | 'AND'
  | 'like'
  | 'between'
  | '==='
  | '<'
  | '>'
  | '<='
  | '>='
  | '!=='
  | 'is'
  | 'in'
  | 'not in';

export namespace QueryBuilder {
  export abstract class Expression<T> {
    abstract readonly type: T;
    public abstract accept<R>(visitor: Visitor<R>, context?: any): R;
  }

  export interface BinaryExpression<
    L extends Expression<any> = Expression<any>,
    R extends Expression<any> = Expression<any>,
    O = SqlOperator,
  > extends Expression<'binary'> {
    readonly type: 'binary';
    left: L;
    operator: O;
    right: R;
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export interface DateExpression<
    L extends Expression<any> = Expression<any>,
    R extends Expression<any> = Expression<any>,
    O = SqlOperator,
  > extends Expression<'date'> {
    readonly type: 'date';
    left: L;
    operator: O;
    right: R;
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export interface Literal<T> extends Expression<T> {
    type: T;
    value: string;
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export type NullLiteral = Omit<Expression<'null'>, 'value'>;

  export interface ListExpr extends Expression<'list'> {
    type: 'list';
    value: Expression<any>[];
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export interface GroupExpr extends Expression<'group'> {
    type: 'group';
    combinator: Combinator;
    value: Expression<any>[];
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export interface QuerySelectExpr extends Expression<'querySelect'> {
    type: 'querySelect';
    value: GroupExpr[];
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export interface Identifier extends Expression<'identifier'> {
    type: 'identifier';
    value: string;
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export interface Combinator extends Expression<'combinator'> {
    type: 'combinator';
    operator: 'and' | 'or';
    accept<R>(visitor: Visitor<R>, context?: any): R;
  }
  export function createBooleanLiteral(value: string): Literal<'boolean'> {
    return {
      value,
      type: 'boolean',
      accept<R>(visitor: Visitor<R>, context?: any): R {
        return visitor.visitBooleanLiteralExpr(this, {
          ...context,
          required: true,
        });
      },
    };
  }
  export function createDateLiteral(value: string): Literal<'date'> {
    return {
      value,
      type: 'date',
      accept<R>(visitor: Visitor<R>, context?: any): R {
        return visitor.visitDateLiteralExpr(this, {
          ...context,
          required: true,
        });
      },
    };
  }
  export function createStringLiteral(
    value: string,
    defaultContext?: any,
  ): Literal<'string'> {
    return {
      value,
      type: 'string',
      accept<R>(visitor: Visitor<R>, context: any = defaultContext): R {
        return visitor.visitStringLiteralExpr(this, {
          ...(context ?? {}),
          required: true,
        });
      },
    };
  }
  export function createNumericLiteral(value: string): Literal<'numeric'> {
    return {
      value,
      type: 'numeric',
      accept<R>(visitor: Visitor<R>, context?: any): R {
        return visitor.visitNumericLiteralExpr(this, {
          ...context,
          required: true,
        });
      },
    };
  }
  export function createNullLiteral(defaultContext?: any): NullLiteral {
    return {
      type: 'null',
      accept<R>(visitor: Visitor<R>, context: any = defaultContext): R {
        return visitor.visitNullLiteralExpr(this, {
          ...(context ?? {}),
          required: true,
        });
      },
    };
  }

  export function createIdentifier(
    value: string,
    defaultContext?: any,
  ): Identifier {
    return {
      value,
      type: 'identifier',
      accept<R>(visitor: Visitor<R>, context: any): R {
        return visitor.visitIdentifier(this, {
          ...defaultContext,
          ...context,
        });
      },
    };
  }
  export function createCombinator(operator: 'and' | 'or'): Combinator {
    return {
      operator,
      type: 'combinator',
      accept<R>(visitor: Visitor<R>, context: any): R {
        return visitor.visitCombinator(this, context);
      },
    };
  }

  export function createBinaryExpression(
    left: Literal<any> | Expression<any>,
    operator: SqlOperator,
    right: Literal<any> | Expression<any>,
    defaultContext?: any,
  ): QueryBuilder.BinaryExpression<any, any> {
    return {
      type: 'binary',
      left,
      operator,
      right,
      accept<R>(visitor: Visitor<R>, context: any = defaultContext): R {
        return visitor.visitBinaryExpr(this, {
          ...defaultContext,
          ...context,
        });
      },
    };
  }
  export function createGroupExpression(
    list: Expression<any>[],
    combinator: Combinator,
    defaultContext?: any,
  ): QueryBuilder.GroupExpr {
    return {
      type: 'group',
      combinator,
      value: list,
      accept<R>(visitor: Visitor<R>, context: any = defaultContext): R {
        return visitor.visitGroupExpr(this, {
          ...defaultContext,
          ...context,
        });
      },
    };
  }

  export function createQuerySelectExpression(
    groups: GroupExpr[],
    defaultContext?: any,
  ): QueryBuilder.QuerySelectExpr {
    return {
      type: 'querySelect',
      value: groups,
      accept<R>(visitor: Visitor<R>, context: any = defaultContext): R {
        return visitor.visitQuerySelectExpr(this, {
          ...defaultContext,
          ...context,
        });
      },
    };
  }

  export function createListExpression(
    list: Expression<any>[],
    defaultContext?: any,
  ): QueryBuilder.ListExpr {
    return {
      type: 'list',
      value: list,
      accept<R>(visitor: Visitor<R>, context: any = defaultContext): R {
        return visitor.visitListExpr(this, {
          ...defaultContext,
          ...context,
        });
      },
    };
  }
}

export abstract class Visitor<R> {
  public abstract visitCombinator(
    expr: QueryBuilder.Combinator,
    context: any,
  ): R;

  public abstract visitGroupExpr(expr: QueryBuilder.GroupExpr, context: any): R;
  public abstract visitQuerySelectExpr(
    expr: QueryBuilder.QuerySelectExpr,
    context: any,
  ): R;
  public abstract visitNumericLiteralExpr(
    expr: QueryBuilder.Literal<'numeric'>,
    context: any,
  ): R;
  public abstract visitListExpr(expr: QueryBuilder.ListExpr, context: any): R;
  public abstract visitBinaryExpr(
    expr: QueryBuilder.BinaryExpression<any, any>,
    context: any,
  ): R;
  public abstract visitNullLiteralExpr(
    expr: QueryBuilder.NullLiteral,
    context: any,
  ): R;
  public abstract visitBooleanLiteralExpr(
    expr: QueryBuilder.Literal<'boolean'>,
    context: any,
  ): R;
  public abstract visitDateLiteralExpr(
    expr: QueryBuilder.Literal<'date'>,
    context: any,
  ): R;
  public abstract visitStringLiteralExpr(
    expr: QueryBuilder.Literal<'string'>,
    context: any,
  ): R;
  public abstract visitIdentifier(
    expr: QueryBuilder.Identifier,
    context: { format: (value: string) => string },
  ): R;
}
