import type { diagnostic } from '@january/sdk/analyzer';
import { Checker } from '@january/sdk/analyzer';

import type { Command } from '@faslh/isomorphic';

import type { RuleFn } from '../evaluate';
import { input } from './functional';
import type { ProjectDefinition } from './project';
import { coerceString } from './utils';
import type { WorkflowDefinition } from './workflow';

const CREATED_AT =
  /^created(_at|At|_At|on|On|_on|_On|[_-]?timestamp|[_-]?date)?$/i;
const UPDATED_AT =
  /^updated(_at|At|_At|on|On|_on|_On|[_-]?timestamp|[_-]?date)?$/i;
const DELETED_AT =
  /^deleted(_at|At|_At|on|On|_on|_On|[_-]?timestamp|[_-]?date)?$/i;

export interface TableDefinition<
  Fields extends Record<string, FieldDefinition> = Record<
    string,
    FieldDefinition
  >,
> {
  fields: Fields;
  constraints: Constraint[];
}

export interface FieldDefinition {
  type: string;
  details: Record<string, unknown>;
  validations: (ValidationDefinition | ValidationDefinition[])[];
}

export interface ValidationDefinition {
  name: string;
  config: Record<string, unknown>;
}

export interface InputDefinition {
  input: string;
  data?: Record<string, unknown>;
}
// function connect(tablea: UseTable, tableb: UseTable) {
//   //
// }

// connect.oneToMany = (tablea: UseTable, tableb: UseTable) => {
//   //
// };

// connect.manyToMany = (tablea: UseTable, tableb: UseTable) => {
//   //
// };

export function table<Fields extends Record<string, FieldDefinition>>(config: {
  fields: Fields;
  constraints?: Constraint[];
  unique?: Constraint;
}): TableDefinition<Fields> {
  const additionalFields: Record<string, FieldDefinition> = {};
  const idField = Object.values(config.fields).find((def) =>
    // TODO: these types should come from the installed database extension
    ['primary-key-uuid', 'primary-key-number', 'primary-key-custom'].includes(
      def.type,
    ),
  );
  if (!idField) {
    additionalFields['id'] = field.primary({
      type: 'uuid',
      generated: true,
    });
  }

  const createdAtField = Object.keys(config.fields).find((key) =>
    CREATED_AT.test(key),
  );
  const updatedAtField = Object.keys(config.fields).find((key) =>
    UPDATED_AT.test(key),
  );
  const deletedAtField = Object.keys(config.fields).find((key) =>
    DELETED_AT.test(key),
  );

  if (!createdAtField) {
    additionalFields['createdAt'] = field({
      type: 'datetime',
      metadata: {
        system_created_at: true,
        can_be_deleted: false,
        can_be_updated: false,
        system_auto_generated: true,
      },
    });
  }

  if (!updatedAtField) {
    additionalFields['updatedAt'] = field({
      type: 'datetime',
      metadata: {
        can_be_deleted: false,
        can_be_updated: false,
        system_auto_generated: true,
        system_updated_at: true,
      },
    });
  }

  if (!deletedAtField) {
    additionalFields['deletedAt'] = field({
      type: 'datetime',
      metadata: {
        system_deleted_at: true,
        system_auto_generated: true,
        can_be_deleted: false,
        can_be_updated: false,
      },
    });
  }

  return {
    fields: {
      ...config.fields,
      ...additionalFields,
    },
    constraints: config.constraints || [],
  };
}

table.unique = <Workflow extends WorkflowDefinition<any>[]>(
  ...fields: UseField<Workflow>[]
): Constraint => {
  return {
    type: 'unique',
    details: {
      columns: fields,
    },
  };
};

table.index = <Workflow extends WorkflowDefinition<any>[]>(
  ...fields: UseField<Workflow>[]
): Constraint => {
  return {
    type: 'index',
    details: {
      columns: fields,
    },
  };
};

// table.relation = (config: {
//   relationship: 'one-to-many' | 'many-to-many' | 'one-to-one';
//   references: UseTable[];
// }): TableDefinition => {
//   return {
//     type: 'relation',
//     fields: {},
//     constraints: [],
//   };
// };

table.use = useTable;

// table.link = connect;
// table.connect = connect;
// table.inherits(tableName).with({ fields: { ... } });

export type UseTable<Workflow> = Command<
  { name: string },
  ProjectDefinition<Workflow>
>;

export function useTable<Workflow>(name: string): UseTable<Workflow> {
  return {
    command: 'QueryTable',
    payload: {
      name,
    },
  };
}
useTable.rule = (({ node }, service) => {
  const reports: diagnostic.Diagnostic[] = [];
  if (!Checker.isCallExpression(node)) {
    return reports;
  }
  const [table] = node.arguments;
  if (typeof table !== 'string') {
    reports.push({
      message: 'Table name must be a string literal',
      span: node.span,
      node: node as any,
      severity: 'error',
    });
  } else {
    const exist = service.hasTable(table);
    if (!exist) {
      reports.push({
        message: `Table "${table}" not found`,
        span: node.span,
        node: node as any,
        severity: 'error',
      });
    }
  }
  return reports;
}) satisfies RuleFn;

export function field(config: {
  type: string;
  validations?: (ValidationDefinition | ValidationDefinition[])[];
  metadata?: Record<string, unknown>;
}): FieldDefinition {
  const { type, validations = [], metadata = {}, ...rest } = config;
  return {
    type: config.type,
    details: {
      ...metadata,
      ...rest,
    },
    validations,
  };
}

export namespace field {
  export function fromConfig(
    type: string,
    ...args: unknown[]
  ): FieldDefinition {
    if (typeof type === 'string') {
      const parts = type.split('.');
      let impl = field;
      while (parts.length) {
        impl = (impl as any)[parts.shift() as any];
      }
      if (impl) {
        return (impl as any)(...args);
      }

      if (type.endsWith('.rule')) {
        const reports: diagnostic.Diagnostic[] = [];
        return reports as never;
      }
      throw new Error(`Unknown field type: ${type}`);
    }
    return field(type as any);
  }

  export function primary(config: {
    // TODO: what about ulid and different uuid versions?
    // maybe add the version as type uuidv4, uuidv5, etc...
    // TODO: the generated flag should be different type depdening on the type
    // for number it should say serial, identity or autoincrement
    // if string then it cannot be auto generated unless we do that as ORM doesn't
    type: 'uuid' | 'number' | 'string';
    generated?: boolean;
  }): FieldDefinition {
    const typesMap: Record<string, string> = {
      uuid: 'primary-key-uuid',
      number: 'primary-key-number',
      string: 'primary-key-custom',
    };

    return {
      type: typesMap[config.type],
      details: {
        system_primary_key: true,
        can_be_deleted: false,
        can_be_updated: false,
        system_auto_generated: config.generated ?? true,
      },
      validations: [],
    };
  }

  export function integer(): FieldDefinition {
    return {
      type: 'integer',
      details: {},
      validations: [],
    };
  }
  export function decimal(): FieldDefinition {
    return {
      type: 'integer',
      details: {},
      validations: [],
    };
  }
  export function relation<Workflow>(config: {
    references: UseTable<Workflow>;
    relationship: 'one-to-one' | 'many-to-one' | 'many-to-many';
    validations?: (ValidationDefinition | ValidationDefinition[])[];
  }): FieldDefinition {
    return field({
      type: 'relation',
      metadata: config,
      validations: config.validations,
    });
  }
}

interface FieldFn {
  (
    name: string,
    config: {
      type: string;
    },
  ): FieldDefinition;
  relation: (name: string, config: Record<string, unknown>) => FieldDefinition;
  use: (name: string) => Command<{ name: string }>;
}

export type UseField<Workflow> = {
  input: string | Command<{ name: string }, ProjectDefinition<Workflow>>;
  name?: string | Command<{ name: string }, ProjectDefinition<Workflow>>;
  data?: Record<string, unknown>;
};

export interface Constraint {
  type: string;
  details: unknown;
}

export function useField<Workflow extends WorkflowDefinition<any>[]>(
  name: string,
  value?: string | number | boolean | null,
): UseField<Workflow> {
  value = coerceString(value);

  if (value === undefined) {
    return {
      input: {
        command: 'QueryFieldName',
        payload: {
          name: name,
        },
      },
    };
  }

  return {
    ...input(value),
    name: {
      command: 'QueryField',
      payload: {
        name: name,
      },
    },
  };
}

useField.rule = (({ node }, service) => {
  const reports: diagnostic.Diagnostic[] = [];
  if (!Checker.isCallExpression(node)) {
    return reports;
  }
  const [fieldName] = node.arguments;
  if (typeof fieldName !== 'string') {
    reports.push({
      message: 'Field name must be a string literal',
      span: node.span,
      node: node as any,
      severity: 'error',
    });
  } else {
    // TODO: we need table name over here!
    // Perhaps fixing this not important when we move to use the table
    // as argument and not string which will make this error being caught by
    // syntax validation
    const exist = service.hasField(fieldName);
    if (!exist) {
      reports.push({
        message: `Field "${fieldName}" not found`,
        span: node.span,
        node: node as any,
        severity: 'error',
      });
    }
  }
  return reports;
}) satisfies RuleFn;

useField.increment = <Workflow extends WorkflowDefinition<any>[]>(
  name: string,
  value: string | number,
): UseField<Workflow> => {
  return {
    ...useField(name, value),
    data: {
      increment: true,
    },
  };
};

useField.decrement = <Workflow extends WorkflowDefinition<any>[]>(
  name: string,
  value: string | number,
): UseField<Workflow> => {
  return {
    ...useField(name, value),
    data: {
      decrement: true,
    },
  };
};

field.use = useField;

export function index<Workflow extends WorkflowDefinition<any>[]>(
  ...fields: UseField<Workflow>[]
): Constraint {
  return {
    type: 'index',
    details: {
      columns: fields,
    },
  };
}
