import { camelcase } from 'stringcase';

import { QueryBuilder, SqlOperator } from './expression';
import type {
  ConditionData,
  GenericQueryCondition,
  GroupQueryCondition,
  QuerySelect,
  SemanticOperators,
} from './interfaces';

const formatInput = (input: string[]) => input.join('.');

function collectJoinsFromGroup(it: GroupQueryCondition<any>): string[] {
  const tablesNames: string[] = [];
  it.data.forEach((it) => {
    if (it.operator === 'querySelect') {
      // Support for subquery not implemented yet
      throw new Error('querySelect must not be inside a group');
    } else if (it.operator === 'group') {
      const groupJoins = collectJoinsFromGroup(it);
      groupJoins.forEach((it) => {
        if (!tablesNames.includes(it)) {
          tablesNames.push(it);
        }
      });
    } else if (it.input.length === 2) {
      const tableOrColumn = it.input[0];
      if (!tablesNames.includes(tableOrColumn) && it.input.length === 2) {
        tablesNames.push(tableOrColumn);
      }
    }
  });
  return tablesNames;
}

function collectJoins(query: QuerySelect) {
  const columns = query.input ?? [];
  const groups = query.data;
  const tablesFromSelect = (columns ?? [])
    .map((it) => {
      if (it.name.split('.').length === 2) {
        return it.name.split('.')[0];
      }
      return '';
    })
    .filter((it) => it !== '');

  const tablesNames: string[] = [...tablesFromSelect];

  groups.forEach((group) => {
    collectJoinsFromGroup(group).forEach((it) => {
      if (!tablesNames.includes(it)) {
        tablesNames.push(it);
      }
    });
  });
  return tablesNames;
}

export function toAst(
  it: GenericQueryCondition<any>,
): QueryBuilder.Expression<any> | null {
  const operator = it.operator as SemanticOperators;
  if (it.operator === 'querySelect') {
    const columns = it.input ?? [];
    const groups = it.data;
    const groupsAst = [];
    for (const group of groups) {
      const combinator = group.input[0];
      if (combinator !== 'and' && combinator !== 'or') {
        throw new Error(`Invalid combinator ${combinator}`);
      }
      if (!group.data.length) {
        continue;
      }
      const groupAst = [];
      for (const item of group.data) {
        const result = toAst(item);
        if (result) groupAst.push(result);
      }

      groupsAst.push(
        QueryBuilder.createGroupExpression(
          groupAst,
          QueryBuilder.createCombinator(combinator),
        ),
      );
    }

    return QueryBuilder.createQuerySelectExpression(groupsAst, {
      columns: columns ?? [],
      joinsFields: collectJoins(it),
    });
  }

  if (it.operator === 'group') {
    const combinator = it.input[0];
    if (combinator !== 'and' && combinator !== 'or') {
      throw new Error(`Invalid combinator ${combinator}`);
    }

    if (!it.data.length) {
      return null;
    }
    const groupAst = [];
    for (const item of it.data) {
      const result = toAst(item);
      if (result) groupAst.push(result);
    }

    return QueryBuilder.createGroupExpression(
      groupAst,
      QueryBuilder.createCombinator(combinator),
    );
  }

  if (it.operator === 'equals') {
    if (it.data.static) {
      return QueryBuilder.createBinaryExpression(
        QueryBuilder.createIdentifier(formatInput(it.input)),
        '===',
        pickLiteral(it.data),
        {
          ...it.data,
          required: true,
        },
      );
    }
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      '===',
      QueryBuilder.createIdentifier(it.input[0]),

      // QueryBuilder.createIdentifier('WILL_BE_USED_FROM_DESTRUCTURED_INPUT'),
      it.data,
    );
  }

  if (it.operator === 'one_of') {
    const oneOfArr = it.data.value as any[];
    if (it.data.static) {
      return QueryBuilder.createBinaryExpression(
        QueryBuilder.createIdentifier(formatInput(it.input)),
        'in',
        QueryBuilder.createListExpression(oneOfArr.map((a) => pickLiteral(a))),
        { ...it.data, required: true },
      );
    }
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      'in',
      QueryBuilder.createListExpression(
        oneOfArr.map((a) => QueryBuilder.createIdentifier(a)),
      ),
      it.data,
    );
  }

  if (
    it.operator === 'ends_with' ||
    it.operator === 'contains' ||
    it.operator === 'starts_with'
  ) {
    if (it.data.static) {
      return QueryBuilder.createBinaryExpression(
        QueryBuilder.createIdentifier(formatInput(it.input)),
        'like',
        pickLiteral(it.data),
        {
          ...it.data,
          required: true,
        },
      );
    }
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      'like',
      QueryBuilder.createIdentifier(it.data.value),
      it.data,
    );
  }

  if (it.operator === 'is') {
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      'is',
      pickLiteral(it.data),
      {
        ...it.data,
        required: true,
      },
    );
  }

  if (it.operator === 'is_empty') {
    const combinator = it.data.inverse
      ? QueryBuilder.createCombinator('and')
      : QueryBuilder.createCombinator('or');

    const identifier = QueryBuilder.createIdentifier(formatInput(it.input));

    const nullBnryExpr = QueryBuilder.createBinaryExpression(
      identifier,
      'is',
      QueryBuilder.createNullLiteral(),
      {
        inverse: it.data.inverse,
        required: true,
      },
    );

    if (it.data.type === 'string') {
      const stringBnryExpr = QueryBuilder.createBinaryExpression(
        identifier,
        'is',
        QueryBuilder.createStringLiteral(''),
        {
          inverse: it.data.inverse,
          required: true,
        },
      );

      return QueryBuilder.createGroupExpression(
        [stringBnryExpr, nullBnryExpr],
        combinator,
        {
          inverse: it.data.inverse,
        },
      );
    } else {
      return QueryBuilder.createGroupExpression([nullBnryExpr], combinator, {
        inverse: it.data.inverse,
      });
    }
  }

  const comparisonMap: Partial<Record<SemanticOperators, SqlOperator>> = {
    less_than: '<',
    more_than: '>',
    less_than_or_equal: '<=',
    more_than_or_equal: '>=',
  };

  if (comparisonMap[operator]) {
    const sqlOperator = comparisonMap[operator] as SqlOperator;
    if (it.data.static) {
      return QueryBuilder.createBinaryExpression(
        QueryBuilder.createIdentifier(formatInput(it.input)),
        sqlOperator,
        pickLiteral(it.data),
        {
          ...it.data,
          required: true,
        },
      );
    }
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      sqlOperator,
      QueryBuilder.createIdentifier(it.data.value),
      // the ui should validate that the value is string in case of dynamic
      it.data,
    );
  }

  const dateMap: Partial<Record<SemanticOperators, SqlOperator>> = {
    before: '<',
    after: '>',
    before_on: '<=',
    after_on: '>=',
  };
  if (dateMap[operator]) {
    const sqlOperator = dateMap[operator] as SqlOperator;
    if (it.data.static) {
      return QueryBuilder.createBinaryExpression(
        QueryBuilder.createIdentifier(formatInput(it.input)),
        sqlOperator,
        pickLiteral(it.data),
        {
          ...it.data,
          required: true,
        },
      );
    }
    // else if (it.data.kind === '@period') {
    //   return QueryBuilder.createBinaryExpression(
    //     QueryBuilder.createIdentifier(formatInput(it.input)),
    //     sqlOperator,
    //     pickDateOperation(it.data),
    //     { ...it.data, required: true },
    //   );
    // }
    // return QueryBuilder.createBinaryExpression(
    //   QueryBuilder.createIdentifier(formatInput(it.input)),
    //   sqlOperator,
    //   QueryBuilder.createIdentifier(it.data.value), // the ui should validate that the value is string in case of dynamic
    // );
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      sqlOperator,
      pickDateOperation(it.data),
      { ...it.data, required: true },
    );
  }

  if (it.operator === 'between') {
    return QueryBuilder.createBinaryExpression(
      QueryBuilder.createIdentifier(formatInput(it.input)),
      'between',
      QueryBuilder.createBinaryExpression(
        it.data.min.kind === '@fixed'
          ? QueryBuilder.createStringLiteral(it.data.min.value)
          : QueryBuilder.createIdentifier(it.data.min.value),
        'AND',
        it.data.max.kind === '@fixed'
          ? QueryBuilder.createStringLiteral(it.data.max.value)
          : QueryBuilder.createIdentifier(it.data.max.value),
      ),
    );
  }
  throw new Error(`Operator is not supported. operator: ${it.operator}`);
}

function pickLiteral(data: ConditionData) {
  switch (data.type) {
    case 'string':
      return QueryBuilder.createStringLiteral(data.value as string);
    case 'number':
      return QueryBuilder.createNumericLiteral(data.value as string);
    case 'date':
      return QueryBuilder.createDateLiteral(data.value as string);
    case 'boolean':
      return QueryBuilder.createBooleanLiteral(data.value as string);
    case 'null':
      return QueryBuilder.createNullLiteral();
    default:
      if (data.static && data.value === 'null') {
        return QueryBuilder.createNullLiteral();
      }
      throw new Error(`Data Type ${data.type} Not Supported`);
  }
}

function pickDateOperation(data: {
  period: string;
  relativeTo: string;
  amount?: number;
}) {
  const { period, relativeTo, amount } = data;

  if (!['day', 'week', 'month', 'quarter', 'year'].includes(period)) {
    throw new Error(`Period ${period} Not Supported`);
  }
  if (!amount) {
    return QueryBuilder.createIdentifier(camelcase(`current_${period}()`));
  }
  const args: string[] = [];

  //TODO - I need to check if relativeTo is an ISO Date
  if (relativeTo && relativeTo !== 'now') {
    args.push(`date: '${relativeTo}'`);
  }
  args.push(`amount: ${amount}`);
  const argsObjectLiteral = `{ ${args.join(', ')} }`;
  return QueryBuilder.createIdentifier(`${period}(${argsObjectLiteral})`);
}
