import type { Token } from './token';

export function tokeniser(input: string): Token[] {
  let index = 0;
  const tokens: Token[] = [];
  let lexeme = '';
  while (index < input.length) {
    const char = input[index];
    switch (char) {
      case '=':
        if (lexeme) {
          tokens.push({
            type: 'IDENTIFIER',
            value: lexeme,
            column: index,
          });
          lexeme = '';
        }
        tokens.push({
          type: 'EQUALS',
          value: char,
          column: index,
        });
        index++;
        break;
      case '!':
        if (input[index + 1] === '=') {
          if (lexeme) {
            tokens.push({
              type: 'IDENTIFIER',
              value: lexeme,
              column: index,
            });
            lexeme = '';
          }
          tokens.push({
            type: 'NOT_EQUALS',
            value: '!=',
            column: index,
          });
          index += 2;
        } else {
          lexeme += char;
          index++;
        }
        break;
      case '.':
        if (lexeme) {
          tokens.push({
            type: 'IDENTIFIER',
            value: lexeme,
            column: index,
          });
          lexeme = '';
        }
        tokens.push({
          type: 'DOT',
          value: char,
          column: index,
        });
        index++;
        break;
      case ',':
        if (lexeme) {
          tokens.push({
            type: 'IDENTIFIER',
            value: lexeme,
            column: index,
          });
          lexeme = '';
        }
        tokens.push({
          type: 'COMMA',
          value: char,
          column: index,
        });
        index++;
        break;
      case '@':
        if (lexeme) {
          tokens.push({
            type: 'IDENTIFIER',
            value: lexeme,
            column: index,
          });
          lexeme = '';
        }
        tokens.push({
          type: 'AT',
          value: char,
          column: index,
        });
        index++;
        break;
      case ':':
        tokens.push({
          type: 'IDENTIFIER',
          value: lexeme,
          column: index,
        });
        lexeme = '';

        tokens.push({
          type: 'COLON',
          value: char,
          column: index,
        });
        index++;
        break;
      case "'":
      case '"':
        {
          index++;
          while (input[index] !== "'" && input[index] !== '"') {
            lexeme += input[index];
            index++;
          }
          index++;
          const column = index;
          if (input[index] === ']') {
            lexeme += input[index];
            index++;
          }
          tokens.push({
            type: 'STRING',
            value: lexeme,
            column: column,
          });
          lexeme = '';
        }
        break;
      case '(':
        tokens.push({
          type: 'IDENTIFIER',
          value: lexeme,
          column: index,
        });
        lexeme = '';
        tokens.push({
          type: 'OPEN_PAREN',
          value: char,
          column: index,
        });
        index++;
        break;
      case ')':
        if (lexeme) {
          tokens.push({
            type: 'IDENTIFIER',
            value: lexeme,
            column: index,
          });
          lexeme = '';
        }
        lexeme = '';

        tokens.push({
          type: 'CLOSE_PAREN',
          value: char,
          column: index,
        });
        index++;
        break;
      case ' ':
      case '\r':
      case '\t':
        // Ignore whitespace.
        if (lexeme) {
          tokens.push({
            type: 'IDENTIFIER',
            value: lexeme,
            column: index,
          });
          lexeme = '';
        }
        index++;
        break;
      default:
        // assume string
        lexeme += char;
        index++;
        break;
    }
  }
  if (lexeme) tokens.push({ type: 'IDENTIFIER', value: lexeme });
  tokens.push({ type: 'EOF', value: '' });
  return tokens;
}
